import { configureStore } from "@reduxjs/toolkit";
import UserReduer from "../slices/user.slice";
import RoleReduer from "../slices/role.slice";
import ShopReduer from "../slices/shop.slice";
import ClientReduer from "../slices/client.slice";
import FeedbackReduer from "../slices/feedback.slice";
import FeedbackHint from "../slices/feedbackReason.slice";
import Profile from "../slices/profile.slice";

const reducer ={ 
    user: UserReduer,
    role: RoleReduer,
    shop: ShopReduer,
    client: ClientReduer,
    feedback: FeedbackReduer,
    feedbackhint: FeedbackHint,
    profile: Profile,
}

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    devTools: process.env.NODE_ENV==="development"?true:false
})

export default store;