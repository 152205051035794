import React, { Fragment, useContext, useEffect, useReducer } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { TitleUContext } from '../../../providers/TitleProvider'
import { useLocation } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import { MdSave } from 'react-icons/md'
import { CgArrowLeft } from 'react-icons/cg'
import LinkButton from '../../../fragments/button/LinkButton'
import { Form } from 'react-bootstrap'
import { InputControl } from '../../../fragments/form/Input'
import { SwitchControl } from '../../../fragments/form/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { roleAdd, rolePermit } from '../../../utilis/slices/role.slice'
import { replaceWith } from '../../../utilis/_helper'
import FormReducer, { FormError } from '../../../utilis/actions/FormReducer'
const formIntial = {
    name: "",
    permissions: [],
}
export default function Add() {
    
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const dispatch = useDispatch()
	// const nevigate = useNavigate()
	const {datas, dataLoading, formError, submitLoading} = useSelector((state) => state.role)
    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const [formEror , dispatchFormErr] = useReducer(FormError, formIntial)

	// Change Page Title
	useEffect(() => {
		setTitle({
			title: "User Manage",
			pageTitle: "User Manage",
			subPageTitle: "Role - Add",
		})
	},[pathname, setTitle])

	useEffect(() => {
		dispatch(rolePermit())
	}, [dispatch])

	const handleFormField = (e, name) => {
		e.preventDefault();
		dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?e.value:e.target.value,
        })
	}
	const handlePermitField = (e) => {
		// e.preventDefault();
		if(formData.permissions.length > 0){
			if(!e.target.checked)
				formData.permissions = formData.permissions.filter(row => row !== parseFloat(e.target.value))
			else
				formData.permissions.push(parseFloat(e.target.value))
			
		} else {
			formData.permissions.push(parseFloat(e.target.value))
		}
		dispatchForm({
            type: "INPUT",
            field: "permissions",
            value: formData.permissions,
        })
	}
	// form submitting
	const handleSubmit = async (e) => {
        e.preventDefault();
		// Current Form Error reset
        dispatchFormErr({
            type: "RESET"
        })
        // Form submtting
        await dispatch(roleAdd(formData))
		dispatchForm({
            type: "RESET",
			formIntial
        })
	}

	// Form validation error
	useEffect(() => {
		if(formError)
        	Object.keys(formError).map((key, idk) => {
			    dispatchFormErr({
			        type: "ERROR",
			        field: key,
			        error: formError[key],
			    })
			    return false;
			})
        
    }, [formError])

    return (
        <Fragment>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Role Add</CardTitle>
						<CardTool>
							<LinkButton to={'..'} className="btn-secondary"><CgArrowLeft/> Back</LinkButton>
						</CardTool>
					</CardHeader>
					<CardBody>
						<p className='my-2'></p>
						<div>
							<Form onSubmit={handleSubmit}>
								<Form.Group className="position-relative mb-3">
									<Form.Label>Name</Form.Label>
									<div className='col-md-8 col-sm-12 col-lg-5'>
										<InputControl 
											type="text"
											name="name"
											placeholder="Role name"
											onChange={handleFormField}
										/>
									</div>
									{
										formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
									}
								</Form.Group>
								<Form.Group className="position-relative mb-3">
									<Form.Label>Permissions</Form.Label>
									{
										formEror?.permissions && typeof formEror?.permissions[0] != "number" && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.permissions}</Form.Control.Feedback>
									}
									<div className='row flex-wrap'>
										{
											dataLoading?<p>Loading...</p>
											:datas.map((row, idx) => (
												<div key={idx} className='col-lg-2 col-md-2 col-sm-3'>
													<SwitchControl // prettier-ignore
														type="switch"
														id={`custom-switch${idx}`}
														value={row.id}
														label={replaceWith(row.name)}
														onChange={handlePermitField}
													/>
												</div>
											))
										}
									</div>
								</Form.Group>
								<div className='d-flex justify-content-end'>
									<Button className="btn-primary" loading={submitLoading}><MdSave/> Save</Button>
								</div>
							</Form>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		</Fragment>
    )
}