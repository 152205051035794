import React, { Fragment, useContext, useEffect, useState } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { Table, Thead } from '../../../fragments/table/Table'
import { Pagination } from '../../../fragments/pagination/Pagination'
import { TitleUContext } from '../../../providers/TitleProvider'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2'
import { IoMdEye } from 'react-icons/io'
import LinkButton from '../../../fragments/button/LinkButton'
import FilterUI from '../../../fragments/uilitis/FilterUI'
import SearchInput from '../../../fragments/form/SearchInput'
import { useDispatch, useSelector } from 'react-redux'
import { feedbackList } from '../../../utilis/slices/feedback.slice'

export default function List() {
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const [searchParams, ] = useSearchParams()
	const page = searchParams.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
	const {datas, dataLoading, meta} = useSelector((state) => state.feedback)

	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "Feedback Manage",
            pageTitle: "Feedback"
		})
	},[pathname, setTitle])

	// Fetch Data
	useEffect(() => {
		const shops = (page) => {
			dispatch(
				feedbackList({
					perPage: 20,
					page: page
				})
			)
		}
		shops(currentPage)
	}, [currentPage, dispatch])

	const handleSearch = (e) => {
		if(e.target.value.length < 4) return; 
	}
	
	return (
		<Fragment>
			<Outlet/>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Feedback List</CardTitle>
					</CardHeader>
					<CardBody className="p-0">
						<FilterUI>
							<div className='d-flex justify-content-between align-content-center'>
								<div className='row flex-grow-1'>
									<div className='col-md-3 ms-1'>
										<SearchInput placeholder="Search client or shop ..." onChange={handleSearch}/>
									</div>
								</div>
							</div>
						</FilterUI>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th>No.</th>
										<th>Client</th>
										<th>Shop</th>
										<th>Average Rate</th>
										<th>Feedback Count</th>
										<th>Created At</th>
										<th width="120">Action</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={8}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idx) => (
											<tr key={idx}>
												<td>{`${idx+parseInt(currentPage)}.`}</td>
												<td>{ row.client }</td>
												<td>{ row.shop }</td>
												<td>{ row.rate }</td>
												<td>{ row.fb_count }</td>
												<td>{ row.created_at }</td>
												<td>
													<div className='btn-group'>
														<LinkButton to={`details/${row.shop_id}?shop=${row.shop}`} className="btn-info btn-sm"><IoMdEye/></LinkButton>
													</div>
												</td>
											</tr>
										))
									}
									{
										((datas.length <= 0) || dataLoading) &&
										(
											<tr>
												{
													datas.length <= 0 && <td colSpan={8}><p className='text-center text-danger'>Empty data</p></td>
												}
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		</Fragment>
	)
}
