import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { createContext } from 'react'

import { userLogout } from '../utilis/slices/user.slice'
import { useDispatch } from 'react-redux';

const AuthContext = createContext({
    token: "",
    isLoggedIn: false,
    user: {},
    login: (token, user_id) => {},
    logout: () => {},
})

export const AuthProvider = (props) => {

    const initialToken = localStorage.getItem("token");
    const initialUser = localStorage.getItem("user");
    const [token, setToken] = useState(initialToken);
    const [user, setUser] = useState(JSON.parse(initialUser));
    const dispatch = useDispatch()
    const loginHandler = (token, user) => {
        setToken(token);
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        localStorage.setItem("isLogout", false);
    };

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem("token");
        localStorage.setItem("isLogout", true);
        dispatch(userLogout)
    };

    const contextValue = {
        token: token,
        isLoggedIn: !!token,
        user: user,
        login: loginHandler,
        logout: logoutHandler,
    };

    return (
        <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};