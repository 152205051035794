import APIService from "../api.service";

export const List = (params) => {
    return APIService.get(`/shops`, {
        params: {
            per_page: params.perPage,
            page: params.page
        }
    });
}

export const Detail = (id, config={}) => {
    return APIService.get(`/shops/${id}`, config);
}

export const Create = (body, config={}) => {
    return APIService.post(`/shops`, body, config);
}

export const Patch = (id, body, config={}) => {
    return APIService.put(`/shops/${id}`, body, config);
}

export const Delt = (id, config={}) => {
    return APIService.delete(`/shops/${id}`, config);
}

const userService = {
    List,
    Detail,
    Create,
    Patch,
    Delt
}
export default userService;