import axios from 'axios';
import { APIUri } from '../../_config';

const APIService = axios.create({
    baseURL : APIUri('/api')
  });
  APIService.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.accessToken = token;
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  
  APIService.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403) {
        // localStorage.setItem("isLogout", true);
        localStorage.removeItem("token");
        window.location = '/login';
      }
      return Promise.reject(error);
    }
  );

export default APIService
