const FormReducer = (state, action) =>{
    switch (action.type) {
        case "INPUT":
            return {
                ...state,
                [action.field] : action.value,
            }
        case "RESET":
            return {}
        case "REPLACE":
            return action.value;
        default:
            return state;
    }
} 
export default FormReducer;

export const FormError = (state, action) => {
    switch (action.type) {
        case "RESET":
            return {
            }
        case "ERROR":
            return {
                ...state,
                [action.field] : action.error,
            }
        case "REPLACE":
            return action.error;
        default:
            return state;
    }
}