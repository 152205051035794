import React, { useContext } from 'react'
import { styled } from 'styled-components'
import { SideBarContext } from '../../providers/SidebarProvider'
import BreadcrumbHeader from './Breadcrumb'
import { Outlet } from 'react-router-dom'

export default function Content() {
    const {open} = useContext(SideBarContext)
    return (
        <MainContent className='content__section' open={open}>
            <BreadcrumbHeader/>
            <BoxContent>
                <div>
                    <Outlet/>
                </div>
            </BoxContent>
            <FooterContent open={open}>
                <div className='d-flex justify-content-between'>
                    <span>2023 @ IGS</span>
                    <span>Design & Develop by IGS</span>
                </div>
            </FooterContent>
        </MainContent>
    )
}

const MainContent = styled.div`
    margin-left: ${props => props.open?"var(--sidebar-sm-size)":"var(--sidebar-size)"};
    margin-top: var(--topbar-size);
    -webkit-transition: width,left,right,300ms;
    transition: width,left,right,300ms;
`

const BoxContent = styled.div`
    padding: calc(1.5rem) calc(1.5rem * .5) 60px calc(1.5rem * .5);
    min-height: calc(100vh - var(--topbar-size) - 103px);
    position: relative;
`

const FooterContent = styled.footer`
    /* bottom: 0; */
    padding: 20px calc(1.5rem * .5);
    position: absolute;
    right: 0;
    color: var(--gray-4);
    left: ${props => props.open?"var(--sidebar-sm-size)":"var(--sidebar-size)"};
    height: 60px;
    background-color: var(--gray-0);
    -webkit-transition: width,left,right,300ms;
    transition: width,left,right,300ms;
    font-size: .86rem;
`