import { styled } from "styled-components"
import { DOTS, usePagination } from "./usePagination"
import { Link } from "react-router-dom"

export const Pagination = (props) => {
    const {
        className,
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        ...rest
    } = props
    
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    })
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        if((currentPage * pageSize)>=totalCount) return;
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        if(currentPage===1) return;
        onPageChange(currentPage - 1);
    };

    // let lastPage = paginationRange[paginationRange.length - 1];

    return(
        <PaginStyle className={`${className}`} {...rest}>
            <ul
                className="pagination"
            >
                <li
                    className={`page-item`}
                    onClick={onPrevious}
                >
                    <Link className={`page-link ${(currentPage * pageSize)<=1 && 'disabled'}`} to={(currentPage!==1)&&`?page=${currentPage-1}`} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </Link>
                </li>
                {
                    paginationRange.map(pageNumber => {
                        if (pageNumber === DOTS) {
                            return <li className="pagination-item dots">&#8230;</li>;
                        }

                        return (
                            <li
                                key={pageNumber}
                                className="page-item"
                                onClick={() => onPageChange(pageNumber)}
                            >
                                <Link className={`page-link ${currentPage === pageNumber && 'disabled'}`} to={`?page=${pageNumber}`}>
                                    {pageNumber}
                                </Link>
                            </li>
                        );
                    })
                }
                <li 
                    className="page-item"
                    onClick={onNext}>
                    <Link className={`page-link ${(currentPage * pageSize)>=totalCount && 'disabled'}`} to={`?page=${(currentPage * pageSize)>=totalCount?currentPage:currentPage+1}`} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </Link>
                </li>
            </ul>
        </PaginStyle>
    )
}
const PaginStyle = styled.nav`
    .page-link:focus{
        box-shadow: unset;
    }
`