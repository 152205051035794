import { createContext, useState } from 'react'

export const TitleUContext = createContext({
    title: '',
    pageTitle: '',
    subPageTitle: '',
    setTitle: ({
        title= '',
        pageTitle= '',
        subPageTitle= ''
    }) => {},
})

export const TitleProviders = (props) => {
    const [content, setContent] = useState({})

    const context = {
        title: content?.title,
        pageTitle: content?.pageTitle,
        subPageTitle: content?.subPageTitle,
        setTitle: setContent,
    };

    document.title = content?.title + (content?.subPageTitle?`(${content?.subPageTitle})`:'')

    return (
        <TitleUContext.Provider value={context}>{props.children}</TitleUContext.Provider>
    )
}
