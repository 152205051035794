import React from 'react'
import { Container } from 'react-bootstrap'
import TopBar from './TopBar'
import Content from './Content'
import Asidebar from './Sidebar'
import { Toaster } from 'react-hot-toast'

export default function Main() {
  return (
    <Container fluid className='p-0'>
        <TopBar/>
        <Asidebar/>
        <Content/>
        <Toaster />
    </Container>
  )
}
