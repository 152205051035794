import APIService from "../api.service";

export const List = (params) => {
    return APIService.get(`/roles`,{
        params: {
            per_page: params?.perPage,
            page: params?.page?params?.page:1
        }
    });
}

export const Permission = (params) => {
    return APIService.get(`/permissions`,{
        params: {
            per_page: params?.perPage?params?.perPage:100,
            page: params?.page?params?.page:1
        }
    });
}

export const Detail = (params) => {
    return APIService.get(`/roles/${params}`);
}
export const Create = (data) => {
    return APIService.post(`/roles`, data);
}
export const Patch = (params, data) => {
    return APIService.put(`/roles/${params}`, data);
}
export const Delt = (params) => {
    return APIService.delete(`/roles/${params}`);
}

const roleService = {
    Permission,
    List,
    Detail,
    Create,
    Patch,
    Delt
}
export default roleService;