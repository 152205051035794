import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from '../../../fragments/button/Button'
import { MdSave } from 'react-icons/md'
import { InputControl } from '../../../fragments/form/Input'
import { TextareaControl } from '../../../fragments/form/Textarea'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useReducer } from 'react'
import { FormError } from '../../../utilis/actions/FormReducer'
import { SelectControl } from '../../../fragments/form/Select'
import { clientList } from '../../../utilis/slices/client.slice'

export default function RoleForm({
    handleSubmit,
    formIntial,
    dispatchForm,
    formData,
}) {

    const { submitLoading, formError } = useSelector((state) => state.shop)
    const client = useSelector((state) => state.client)
    const [formEror , dispatchFormErr] = useReducer(FormError, formIntial)
    const [ options, setOptions] = useState([])
	const dispatch = useDispatch()

    
    const handleFormField = (e, name) => {
        dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?e.value:e.target.value,
        })
    }
	// Form validation error
	useEffect(() => {
		if(formError){
            let formIssue = {}
            Object.keys(formError).map((key, idk) => {
                formIssue = {
                    ...formIssue,
                    [key]: formError[key]
                }
			    return formIssue;
			})
            dispatchFormErr({
                type: "REPLACE",
                error: formIssue,
            })
        } else {
            // Reset form validation 
            dispatchFormErr({
                type: "RESET"
            })
        }        
    }, [formError])

	useEffect(() => {
		dispatch(clientList({perPage: 100}))
	}, [dispatch])
    useEffect(() => {
        const opts = client.datas?.map(row => { return {value: row.id, label: `${row.name} (${row.phone})`}})
        setOptions(opts)
    },[client.datas])

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="position-relative mb-3">
                    <Form.Label>Shop Name</Form.Label>
                    <InputControl 
                        type="text"
                        required
                        name="name"
                        placeholder="Shop name"
                        value={formData?.name}
                        onChange={handleFormField}
                    />
                    {
                        formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group className="position-relative mb-3">
                    <Form.Label>Clients</Form.Label>
                    <SelectControl
                            required
                            placeholder="Choose Client"
                            name="client_id"
                            value={options.find(row => row.value===formData.client)}
                            options={options}
                            onChange={(e) => handleFormField(e, 'client_id')}
                        />
                        {
                            formEror?.roles && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.roles}</Form.Control.Feedback>
                        }
                </Form.Group>
                <div className='row'>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Shop Email</Form.Label>
                        <InputControl 
                            type="text"
                            name="email"
                            placeholder="Shop Email"
                            value={formData?.email}
                            onChange={handleFormField}
                        />
                        {
                            formEror?.email && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.email}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Shop Phone</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="phone"
                            value={formData?.phone}
                            placeholder="Shop Phone"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.phone && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.phone}</Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-4">
                        <Form.Label>Country</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="country"
                            value={formData?.country}
                            placeholder="Country"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.country && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.country}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-4">
                        <Form.Label>Reginon/State</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="region"
                            value={formData?.region}
                            placeholder="Reginon/State"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.region && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.region}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-4">
                        <Form.Label>Township/Zip</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="township"
                            value={formData?.township}
                            placeholder="Township/Zip"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.township && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.township}</Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
                <Form.Group className="position-relative mb-3">
                    <Form.Label>Shop Address</Form.Label>
                    <TextareaControl 
                        row={3} 
                        name="address" 
                        value={formData?.address}
                        required 
                        placeholder="Type your shop address..."
                        onChange={handleFormField}></TextareaControl>
                    {
                        formEror?.address && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.address}</Form.Control.Feedback>
                    }
                </Form.Group>
                <div className='row'>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Latitude</Form.Label>
                        <InputControl 
                            type="text"
                            name="latitude"
                            value={formData?.latitude}
                            placeholder="Latitude"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.latitude && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.latitude}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Longitude</Form.Label>
                        <InputControl 
                            type="text"
                            name="longitude"
                            value={formData?.longitude}
                            placeholder="Longitude"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.longitude && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.longitude}</Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button className="btn-primary" loading={submitLoading}><MdSave/> Save</Button>
                </div>
            </Form>
        </div>
    )
}
