import React, { useReducer, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import Button from '../../../fragments/button/Button'
import { Modals } from '../../../fragments/modal/Modal'
import { InputControl } from '../../../fragments/form/Input'
import { SelectControl } from '../../../fragments/form/Select'
import FormReducer, { FormError } from '../../../utilis/actions/FormReducer'
import { useEffect } from 'react'
import { userAdd, userEdit } from '../../../utilis/slices/user.slice'
import { useDispatch, useSelector } from 'react-redux'
import { roleList } from '../../../utilis/slices/role.slice'

const formIntial = {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    role: {}
}

export default function Add(props) {
    const { 
        userId,
        handleClose,
        show
    } = props

    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const [formEror, dispatchFormErr] = useReducer(FormError, formIntial)
    const { submitLoading, formError, status, datas } = useSelector((state) => state.user)
    const roleState = useSelector((state) => state.role)
    const [ options, setOptions] = useState([])
    const dispatch = useDispatch()

    const handleFormField = (e, name) => {
        console.log(e);
        dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?[e.value]:e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Current Form Error reset
        dispatchFormErr({
            type: "RESET"
        })
        // Form submtting
        if(userId){
            dispatch(
                userEdit({id: userId, data: formData})
            )
        } else {
            dispatch(
                userAdd(formData)
            )
        }
    }
    // Close form modal
    const handleCloseModal = () => {
		handleClose()
        dispatchForm({
            type: "RESET"
        })
	}
    // Handle close success submitted
    useEffect(() => {
        if(status){
            handleCloseModal()
        }
        return;
    }, [status])
    // get role list
    useEffect(() => {
        if(!show) return;
        dispatch(
            roleList({perPage: 100})
        )
    }, [dispatch, show])
    // Role option modified
    useEffect(() => {
        if(!show) return;
        const opts = roleState.datas?.map(row => { return {value: row.id, label: row.name}})
        setOptions(opts)
    }, [roleState.datas, show])
    // form error show from response
    useEffect(() => {
        if(formError)
            Object.keys(formError).map((key, idk) => {
                dispatchFormErr({
                    type: "ERROR",
                    field: key,
                    error: formError[key],
                })
                return false;
            })
        
    }, [formError])

    // For form edit
    useEffect(() => {
        if(!userId) return;
        const userdata = datas.find((row) => row.id===userId)
        const role = userdata.roles.map((row) => row.id);
        // console.log(c[0]);
        dispatchForm({
            type: "REPLACE",
            value: {
                name: userdata.name,
                phone: userdata.phone,
                email: userdata.email,
                roles: role
            },
        })
    }, [datas, userId])

    return (
        <Modals show={show} onHide={handleCloseModal}> 
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{userId?"Edit":"Add"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Name</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="name"
                            value={formData?.name}
                            placeholder="User name"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Phone</Form.Label>
                        <InputControl 
                            type="text"
                            name="phone"
                            value={formData?.phone}
                            placeholder="User phone"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.phone && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.phone}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Email</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="email"
                            value={formData?.email}
                            placeholder="User email"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.email && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.email}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Password</Form.Label>
                        <InputControl 
                            type="password"
                            name="password"
                            placeholder="xxxxxxxx"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.password && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.password}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputControl 
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.confirm_password && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.confirm_password}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Role</Form.Label>
                        <SelectControl
                            required
                            placeholder="Choose Role"
                            name="role"
                            value={options.find(row => row.value===formData.roles?.[0])}
                            options={options}
                            onChange={(e) => handleFormField(e, 'roles')}
                        />
                        {
                            formEror?.roles && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.roles}</Form.Control.Feedback>
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-outline-success" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button type="submit" className="btn-primary" loading={submitLoading}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modals>
    )
}