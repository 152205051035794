import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import Button from '../../../fragments/button/Button'
import { Modals } from '../../../fragments/modal/Modal'
import { InputControl } from '../../../fragments/form/Input'
import { useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormReducer, { FormError } from '../../../utilis/actions/FormReducer'
import { clientAdd, clientEdit } from '../../../utilis/slices/client.slice'
import { useEffect } from 'react'

const formIntial = {
    name: "",
    email: "",
    phone: "",
    services: "",
    image:{}
}

export default function Add(props) {
    const { 
        userId,
        handleClose,
        show
    } = props

    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const [formEror, dispatchFormErr] = useReducer(FormError, formIntial)
    const { submitLoading, formError, status, datas } = useSelector((state) => state.client)
    const dispatch = useDispatch()

    const handleFormField = (e, name) => {
        console.log(e);
        dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?[e.value]:e.target.value,
        })
    }

    const handleFileForm = (e) => {
        const file = e.target.files[0]
        dispatchForm({
            type: "INPUT",
            field: "image",
            value: file,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Current Form Error reset
        dispatchFormErr({
            type: "RESET"
        })
        console.log(formData);
        // Form submtting
        const formBody = new FormData();
        formBody.append("name", formData?.name)
        formBody.append("email", formData?.email)
        formBody.append("phone", formData?.phone)
        formBody.append("services", formData?.services)

        if(typeof formData?.image != 'string'){
            formBody.append("logo", formData?.image)
        }
        
        if(userId){
            dispatch(
                clientEdit({id: userId, data: formBody})
            )
        } else {
            dispatch(
                clientAdd(formBody)
            )
        }
    }
    // Close form modal
    const handleCloseModal = () => {
		handleClose()
        dispatchForm({
            type: "RESET"
        })
	}
    // Handle close success submitted
    useEffect(() => {
        if(status){
            handleCloseModal()
        }
        return;
    }, [status])
    // form error show from response
    useEffect(() => {
        console.log(formError);
        if(formError)
            Object.keys(formError).map((key, idk) => {
                dispatchFormErr({
                    type: "ERROR",
                    field: key,
                    error: formError[key],
                })
                return false;
            })
        
    }, [formError])

    // For form edit
    useEffect(() => {
        if(!userId) return;
        const client = datas.find((row) => row.id===userId)
        // console.log(c[0]);
        dispatchForm({
            type: "REPLACE",
            value: {
                name: client?.name, 
                services: client?.services,
                phone: client?.phone,
                email: client?.email,
                image: client?.logo
            },
        })
    }, [datas, userId])

    return (
        <Modals show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit} action="">
                <Modal.Header closeButton>
                    <Modal.Title>Add</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Group Name</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="services"
                            value={formData?.services}
                            placeholder="Group Name"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.services && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.services}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Client Name</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="name"
                            value={formData?.name}
                            placeholder="Client name"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Client Email</Form.Label>
                        <InputControl 
                            type="email"
                            required
                            name="email"
                            value={formData?.email}
                            placeholder="Client email"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.email && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.email}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Client Phone</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="phone"
                            value={formData?.phone}
                            placeholder="959xxxxxxxxx"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.phone && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.phone}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Logo</Form.Label>
                        <InputControl 
                            onChange={handleFileForm}
                            type="file"
                            name="logo"
                        />
                    </Form.Group>
                    {
                        formEror?.logo && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.logo}</Form.Control.Feedback>
                    }
                    {
                        formData?.image && 
                        <Form.Group className="position-relative mb-3">
                            <div className='w-100 position-relative'>
                                <img className='w-100' src={formData?.image} alt="" />
                            </div>
                        </Form.Group>
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-outline-success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="submit" className="btn-primary" loading={submitLoading}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modals>
    )
}