import APIService from "../api.service";

export const List = (params) => {
    return APIService.get(`/clients`, {
        params: {
            per_page: params.perPage,
            page: params.page
        }
    });
}

export const Detail = (id, config={}) => {
    return APIService.get(`/clients/${id}`, config);
}

export const Create = (body, config={}) => {
    return APIService.post(`/clients`, body, config);
}

export const Patch = (id, body, config={}) => {
    return APIService.post(`/clients/${id}?_method=PUT`, body, config);
}

export const Delt = (id, config={}) => {
    return APIService.delete(`/clients/${id}`, config);
}

const userService = {
    List,
    Detail,
    Create,
    Patch,
    Delt
}
export default userService;