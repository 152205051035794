import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import clientService from "../networks/services/client.service"
import { toast } from "react-hot-toast"
import { toastSuccess, toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: [],
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
    submitLoading: false
}

export const clientList = createAsyncThunk(
    "client/list",
    async ({perPage, page}) => {
        try {
            const resp = await clientService.List({perPage, page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const clientAdd = createAsyncThunk(
    "client/add",
    async (data) => {
        try {
            const resp = await clientService.Create(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const clientOne = createAsyncThunk(
    "client/one",
    async (id) => {
        try {
            const resp = await clientService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const clientEdit = createAsyncThunk(
    "client/edit",
    async ({id, data}) => {
        try {
            const resp = await clientService.Patch(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const clientDelt = createAsyncThunk(
    "client/delt",
    async (id) => {
        try {
            const resp = await clientService.Delt(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const clientAction = createSlice({
    name: "client",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        //# client List region 
        .addCase(clientList.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(clientList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload?.data.data.data]
                state.meta = action.payload?.data.data.meta
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(clientList.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# client List endregion
        //# client List region 
        .addCase(clientOne.pending, (state, action) => {
            state.data = {}
            state.dataLoading = true
        })
        .addCase(clientOne.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload?.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(clientOne.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# client List endregion
        //# client Crate region 
        .addCase(clientAdd.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(clientAdd.fulfilled, (state, action) => {
            state.status = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
                toastSuccess(action.payload?.data.message)
            } else {
                toastValidate(action.payload?.data.errors)
            }
            state.formError = action.payload?.data.errors
            state.submitLoading = false
        })
        .addCase(clientAdd.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# client Crate endregion
        //# client Edit region 
        .addCase(clientEdit.pending, (state, action) => {
            state.info = ""
            state.submitLoading = true
        })
        .addCase(clientEdit.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(action.payload?.data.message)
            } else {
                toastValidate(action.payload?.data.errors)
            }
            state.submitLoading = false
        })
        .addCase(clientEdit.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# client Edit endregion
        //# client Delt region 
        .addCase(clientDelt.pending, (state, action) => {
            state.info = ""
            state.submitLoading = true
        })
        .addCase(clientDelt.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(action.payload?.data.message)
            }
            state.submitLoading = false
        })
        .addCase(clientDelt.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# client Delt endregion
    }
})

const { reducer } = clientAction;

export default reducer;