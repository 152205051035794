import React from 'react'
import { styled } from 'styled-components'

export default function UICard(props) {
    const {
        children
    } = props
    return (
        <TableCardStyle className={'card '+props.className}>{children}</TableCardStyle>
    )
}

const TableCardStyle = styled.div`
    border-radius: 3px;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px rgba(56,65,74,.15);
    box-shadow: 0 1px 2px rgba(56,65,74,.15);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    /* height: var(--vz-card-height);
    color: var(--vz-body-color); */
    word-wrap: break-word;
    background-color: var(--card-bg);
    background-clip: border-box;
    border: var(--card-border-width) solid var(--card-border-color);
    /* border-radius: var(--vz-card-border-radius); */
`

export const CardHeader = (props) => {
    const {
        children,
        className
    } = props
    return(
        <CardHeaderStyle className={`card-header ${className}`}>{children}</CardHeaderStyle>
    )
}
const CardHeaderStyle = styled.div`
    display: flex;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--card-bg);
`

export const CardTitle = (props) => {
    const {
        children,
        className
    } = props
    return(
        <CardTitleStyle className={`card-title ${className}`}>{children}</CardTitleStyle>
    )
}
const CardTitleStyle = styled.h4`
    flex-grow: 1;
    font-size: 16px;
    margin: 6px 0;
    margin-bottom: 13px;
`

export const CardTool = (props) => {
    const {
        children,
        className
    } = props
    return(
        <CardToolStyle className={`card-tools ${className}`}>{children}</CardToolStyle>
    )
}
const CardToolStyle = styled.div`
    flex-shrink: 0;
`

export const CardBody = (props) => {
    const {
        children,
        className
    } = props
    return(
        <CardBodyStyle className={`card-body ${className}`}>{children}</CardBodyStyle>
    )
}
const CardBodyStyle = styled.div`
    flex-shrink: 0;
`

export const CardFooter = (props) => {
    const {
        children,
        className
    } = props
    return(
        <CardFooterStyle className={`card-body ${className}`}>{children}</CardFooterStyle>
    )
}
const CardFooterStyle = styled.div`
    padding: 1rem;
    color: var(--vz-card-cap-color);
    background-color: var(--vz-card-cap-bg);
    border-top: var(--vz-card-border-width) solid var(--vz-card-border-color);
`