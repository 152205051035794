import React from 'react'
import { styled } from 'styled-components'

export default function FilterUI(props) {
    const {
        children,
        ...rest
    } = props
    return (
        <FilterUIStyle {...rest}>{children}</FilterUIStyle>
    )
}

const FilterUIStyle = styled.div`
    padding: 10px;
    .filter-btn{
        margin-right: 6px;
    }
`