import React, { useContext, useEffect } from 'react'
import { TitleUContext } from '../../../../providers/TitleProvider'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import UICard, { CardBody, CardHeader, CardTitle, CardTool } from '../../../../fragments/card/UICard'
import LinkButton from '../../../../fragments/button/LinkButton'
import Button from '../../../../fragments/button/Button'
import { CgArrowLeft } from 'react-icons/cg'
import { MdPrint } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { shopOne } from '../../../../utilis/slices/shop.slice'
import PrintFrame from './PrintFrame'

export default function Index() {
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const params = useParams()
	const [search] = useSearchParams()
	const dispatch = useDispatch()
	const {data, dataLoading} = useSelector((state) => state.shop)
    
	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "Shops",
            pageTitle: "Shops",
			subPageTitle: "Print",
		})
	},[pathname, setTitle])

    useEffect(() => {
        dispatch(shopOne(params.id))
    }, [dispatch, params.id])

    const handlePrint = (e) => {
        window.print()
    }

    return (
        <div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Shop Print ({search.get('shop')})</CardTitle>
						<CardTool>
							<LinkButton to={'..'} className="btn-secondary me-2"><CgArrowLeft/> Back</LinkButton>
							<Button className="btn btn-primary" onClick={handlePrint}><MdPrint/> Print</Button>
						</CardTool>
					</CardHeader>
					<CardBody className="m-auto">
                        {
                            dataLoading?'Loading...':<PrintFrame data={data}/>
                        }
					</CardBody>
				</UICard>
			</div>
		</div>
    )
}
