import React, { useContext, useEffect } from 'react'
import { TitleUContext } from '../../providers/TitleProvider'
import { useLocation } from 'react-router-dom'
import UICard, { CardBody, CardTitle } from '../../fragments/card/UICard'
import CardHeader from 'react-bootstrap/esm/CardHeader'

export default function Dashboard() {
	
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()

	// Change Page Title
	useEffect(() => {
		setTitle({
			title: "Dashboard",
			pageTitle: "Dashboard",
		})
	},[pathname, setTitle])

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-4 col-lg-3'>
					<UICard>
						<CardHeader>
							<CardTitle>Client List</CardTitle>
						</CardHeader>
						<CardBody className="p-0">
							
						</CardBody>
					</UICard>
				</div>
			</div>
		</div>
	)
}
