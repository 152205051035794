import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import roleService from "../networks/services/role.service"
import { toast } from "react-hot-toast"
import { toastSuccess, toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: {},
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    dataLoading: false,
    submitLoading: false
}

export const rolePermit = createAsyncThunk(
    "role/permissions",
    async (params) => {
        try {
            const resp = await roleService.Permission({perPage: params?.perPage, page: params?.page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const roleList = createAsyncThunk(
    "role/list",
    async ({perPage, page}) => {
        try {
            const resp = await roleService.List({perPage, page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const roleAdd = createAsyncThunk(
    "role/add",
    async (data) => {
        try {
            const resp = await roleService.Create(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const roleOne = createAsyncThunk(
    "role/one",
    async (id) => {
        try {
            const resp = await roleService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const roleEdit = createAsyncThunk(
    "role/edit",
    async ({id, data}) => {
        try {
            const resp = await roleService.Patch(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const roleDelt = createAsyncThunk(
    "role/delt",
    async (id) => {
        try {
            const resp = await roleService.Delt(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const roleSlice = createSlice({
    name: "role",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        //# permission List region 
        .addCase(rolePermit.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(rolePermit.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload.data.data.data]
                state.meta = action.payload.data.data.meta
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.dataLoading = false
        })
        .addCase(rolePermit.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# permission List endregion
        //# role List region 
        .addCase(roleList.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(roleList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload.data.data.data]
                state.meta = action.payload.data.data.meta
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.dataLoading = false
        })
        .addCase(roleList.rejected, (state, action) => {
            state.error = action.payload.data
            state.dataLoading = false
        })
        //# role List endregion
        //# role one region 
        .addCase(roleOne.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(roleOne.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.dataLoading = false
        })
        .addCase(roleOne.rejected, (state, action) => {
            state.error = action.payload.data
            state.dataLoading = false
        })
        //# role one endregion
        //# role Crate region 
        .addCase(roleAdd.pending, (state, action) => {
            state.formError = ""
            state.submitLoading = true
        })
        .addCase(roleAdd.fulfilled, (state, action) => {
            state.error = ""
            state.info = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.formError = action.payload.data.errors
            state.submitLoading = false
        })
        .addCase(roleAdd.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# role Crate endregion
        //# role Edit region 
        .addCase(roleEdit.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(roleEdit.fulfilled, (state, action) => {
            state.formError = ""
            state.error = ""
            state.info = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.formError = action.payload.data.errors
            state.submitLoading = false
        })
        .addCase(roleEdit.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# role Edit endregion
        //# role Delt region 
        .addCase(roleDelt.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(roleDelt.fulfilled, (state, action) => {
            state.info = ''
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.submitLoading = false
        })
        .addCase(roleDelt.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# role Delt endregion
    }
})

const { reducer } = roleSlice;

export default reducer;