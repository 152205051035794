import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export function DeleteAlert(actionFunc=()=>{}, cb) {
    
	const MySwal = withReactContent(Swal)

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            actionFunc()
            MySwal.fire(
              'Deleted!',
              'Your record has been deleted.',
              'success'
            )
        }
    })
}