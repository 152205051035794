import { toast } from "react-hot-toast";
import { IoIosWarning } from "react-icons/io"

export const toastValidate = (data=[]) => {
    return toast((t) => (
        <div>
            {
                Object.keys(data).map((row, idk) => <p className="mb-1" key={idk}>{data[row]}</p>)
            }
        </div>
    ), {
        icon: <IoIosWarning/>,
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
};

export const toastWarn = (data) => {
    return toast((t) => (
        <div>
        </div>
    ), {
        icon: <IoIosWarning/>,
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
};

export const toastError = (data) => {
    return toast.error(data)
};

export const toastSuccess = (data) => {
    return toast.success(data)
};