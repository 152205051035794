import React from 'react'
import { Spinner } from 'react-bootstrap'
import { styled } from 'styled-components'

export default function Button(props) {
	const {
		children,
		className,
		loading,
		...rest
	} = props
	return (
		<ButtonStyle className={`btn ${className}`} {...rest} disabled={loading?true:false}>{loading?<><Spinner
			as="span"
			animation="border"
			size="sm"
			role="status"
			aria-hidden="true"
		  /> Loading...</>:children}</ButtonStyle>
	)
}

const ButtonStyle = styled.button`
	border-radius: var(--border-radius);
	&:focus-visible, &:first-child:active:focus-visible{
		box-shadow: none;
	}
`