import { createContext, useState } from 'react'

export const SideBarContext = createContext({
    open: false,
    setOpen: () => {}
})

export const SidebarProviders = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const handleMenuOpen = () => {
        setMenuOpen(!menuOpen)
    }

    const context = {
        open: menuOpen,
        setOpen: handleMenuOpen
    };

    return (
        <SideBarContext.Provider value={context}>{props.children}</SideBarContext.Provider>
    )
}
