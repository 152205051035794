import React from 'react'
import { styled } from 'styled-components'
import { InputControl } from './Input'
import { CgSearch } from 'react-icons/cg'

export default function SearchInput(props) {
    const {
        children,
        mainClass,
        ...rest
    } = props
    return (
        <SearchInputStyle className={mainClass?mainClass:''}>
            <InputControl className="search bg-light border-light" {...rest}/>
            <SearchIcon/>
        </SearchInputStyle>
    )
}

const SearchInputStyle = styled.div`
    position: relative;
    .form-control{
        padding-left: 40px;
    }
`

const SearchIcon = styled(CgSearch)`
    font-size: 14px;
    position: absolute;
    left: 13px;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--arlo-secondary-color);
`