import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import userService from "../networks/services/user.service"
import { toast } from "react-hot-toast"
import { toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: [],
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
    submitLoading: false
}

export const userLogin = createAsyncThunk(
    "user/login", 
    async (data) => {
        try{
            const resp = await userService.Login(data)
            return resp;
        } catch(e) {
            return e.response;
        }
    }
)

export const userLogout = createAsyncThunk(
    "user/logout", 
    async () => {
        try{
            const resp = await userService.Logout()
            return resp;
        } catch(e) {
            return e.response;
        }
    }
)

export const userList = createAsyncThunk(
    "user/list",
    async ({perPage, page}) => {
        try {
            const resp = await userService.List({perPage, page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const userAdd = createAsyncThunk(
    "user/add",
    async (data) => {
        try {
            const resp = await userService.Create(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const userOne = createAsyncThunk(
    "user/one",
    async (id) => {
        try {
            const resp = await userService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const userEdit = createAsyncThunk(
    "user/edit",
    async ({id, data}) => {
        try {
            const resp = await userService.Patch(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const userDelt = createAsyncThunk(
    "user/delt",
    async (id) => {
        try {
            const resp = await userService.Delt(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const userAction = createSlice({
    name: "user",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        .addCase(userLogin.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(userLogin.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = action.payload?.data;
                state.error = "";
                toast.success(action.payload?.data.message);
            } else {
                state.error = action.payload?.data?.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.submitLoading = false;
        })
        .addCase(userLogout.pending, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = action.payload?.data;
                state.error = "";
                toast.success(action.payload?.data.message);
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.submitLoading = false;
        })
        //# User List region 
        .addCase(userList.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(userList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload?.data.data.data]
                state.meta = action.payload?.data.data.meta
                console.log(action.payload?.data.data.meta);
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(userList.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# User List endregion
        //# User List region 
        .addCase(userOne.pending, (state, action) => {
            state.data = {}
            state.dataLoading = true
        })
        .addCase(userOne.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload?.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(userOne.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# User List endregion
        //# User Crate region 
        .addCase(userAdd.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(userAdd.fulfilled, (state, action) => {
            state.status = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
            }
            toastValidate(action.payload?.data.errors)
            state.formError = action.payload?.data.errors
            state.submitLoading = false
        })
        .addCase(userAdd.rejected, (state, action) => {
            state.error = action.payload?.data
            console.log(action.payload?.data);
            state.submitLoading = false
        })
        //# User Crate endregion
        //# User Edit region 
        .addCase(userEdit.pending, (state, action) => {
            state.status = ""
            state.submitLoading = true
        })
        .addCase(userEdit.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
            }
            state.submitLoading = false
        })
        .addCase(userEdit.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# User Edit endregion
        //# User Delt region 
        .addCase(userDelt.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(userDelt.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
            }
            state.submitLoading = false
        })
        .addCase(userDelt.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# User Delt endregion
    }
})

const { reducer } = userAction;

export default reducer;