import Form from 'react-bootstrap/Form';
import { styled } from 'styled-components';

export const InputControl = (props) => {
    const {
        className,
        ...rest
    } = props
    return (
        <FInputStyle className={`${className?className:''}`} {...rest}></FInputStyle>
    )
}

const FInputStyle = styled(Form.Control)`
    &:focus{
        box-shadow: none;
    }
    border-radius: var(--border-radius);
`