import React, { Fragment, useContext, useEffect, useState } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { Table, Thead } from '../../../fragments/table/Table'
import { Pagination } from '../../../fragments/pagination/Pagination'
import { TitleUContext } from '../../../providers/TitleProvider'
import { useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import { MdAdd, MdEdit } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import Add from './Add'
import { userDelt, userList } from '../../../utilis/slices/user.slice'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteAlert } from '../../../fragments/alert/DeleteAlert'

export default function List() {
	
    const {setTitle} = useContext(TitleUContext)
    const {pathname} = useLocation()
	const [searchParams, ] = useSearchParams()
	const page = searchParams.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
	const {datas, dataLoading, meta, status} = useSelector((state) => state.user)
	const [userId, setUserId] = useState(null)

	const [show, setShow] = useState(false)
    // Change Page Title
    useEffect(() => {
        setTitle({
            title: "User Manage",
            pageTitle: "User Manage",
            subPageTitle: "User",
        })
    },[pathname, setTitle])

	const handleEdit = (id) => {
		setShow(true)
		setUserId(id)
	}

	const handleDeleted = (id) => {
		DeleteAlert(
			()=>{
				dispatch(userDelt(id))
			}
		)
	}

	const handleClose = () => {
		setShow(!show)
		setUserId(null)
	}
	const users = (page) => {
		dispatch(
			userList({
				perPage: 20,
				page: page
			})
		)
	}
	// Fetch Data
	useEffect(() => {
		users(currentPage)
	}, [currentPage])
	useEffect(() => {
		if(show) return;
		if(status!=="achieve") return;
		users(currentPage)
	}, [show, status])

	return (
		<Fragment>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>User List</CardTitle>
						<CardTool>
							<Button className="btn-primary" onClick={() => setShow(true)}><MdAdd/> Add</Button>
						</CardTool>
					</CardHeader>
					<CardBody className="p-0">
						<p className='my-2'></p>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th width={60}>No.</th>
										<th>Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th width={120}>Role</th>
										<th width={120}>Create By</th>
										<th width={120}>Create At</th>
										<th width="120">Action</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={5}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idk) => (
											<tr key={idk}>
												<td>{`${idk+parseInt(currentPage)}.`}</td>
												<td>{row.name}</td>
												<td>{row.email}</td>
												<td>{row.phone}</td>
												<td>{row.roles?.[0]?.name}</td>
												<td>{row.updated_by}</td>
												<td>{row.created_at}</td>
												<td>
													<div className='btn-group'>
														{/* <Button className="btn-info btn-sm"><MdAdd/></Button> */}
														<Button onClick={() => handleEdit(row.id)} className="btn-primary btn-sm"><MdEdit/></Button>
														<Button className="btn-danger btn-sm" onClick={() => handleDeleted(row.id)}><IoMdTrash/></Button>
													</div>
												</td>
											</tr>
										))
									}
									{
										((datas.length <= 0) || dataLoading) &&
										(
											<tr>
												{
													datas.length <= 0 && <td colSpan={5}><p className='text-center text-danger'>Empty data</p></td>
												}
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		<Add show={show} userId={userId} handleClose={handleClose}/>
		</Fragment>
	)
}
