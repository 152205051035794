import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

export const ProtectedRoute = ({ children }) => {
    const { token, logout } = useAuth();

    window.addEventListener("storage", (d) => {
        const token = localStorage.getItem('token')
        if(!token){
            logout()
        }
    })
    
    if (!token) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};