import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SideBarContext } from '../../providers/SidebarProvider'
import { Sidebar, Menu, SubMenu, MenuItem } from 'react-pro-sidebar'
import InitialIcon from '../InitialIcon'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'

const menuList = [
    {
        label: "Dashboard",
        icon: "dashboard",
        link: '/',
    },
    {
        label: "Feedback",
        icon: "feedback",
        link: "/feedback"
    },
    {
        label: "Client",
        icon: "client",
        link: "/client"
    },
    {
        label: "Service",
        icon: "servicearea",
        link: "/servicearea"
    },
    {
        label: "User Manage",
        icon: "user_manage",
        link: "/usermanage",
        subMenu: [
            {
                label: "Users",
                link: "/usermanage/user",
            },
            {
                label: "Roles",
                link: "/usermanage/role",
            }
        ]
    },
]

export default function Asidebar() {
    const location = useLocation()
    const {open} = useContext(SideBarContext)
    const { logout } = useAuth()
    const [menus, setMenu] = useState([])
    useEffect(() => {
        setMenu(menuList)
    },[])

    return (
        <Section className={`sidebar__section`} collapsed={open} backgroundColor='var(--arlo-body-color)'>
            <div className='sidebar__image'>
                {
                    open?
                    <img src='/images/logo-sm.jpg' className='w-50' alt=''/>
                    :
                    <h4 className='text'>Infinity Globals</h4>
                }
            </div>
            <SidebarMenu>
                {
                    menus.map((row, idk) => (
                        (!row.subMenu && !row.subMenu?.length > 0)?(
                            <MenuItem component={<Link/>} to={row.link} active={ row.link!=='/' ? (location.pathname).includes(row.link) : location.pathname===row.link} key={idk} icon={InitialIcon(row.icon)}> {row.label} </MenuItem>
                        ):(
                            <SubMenu key={row} label={row.label} defaultOpen={location.pathname.includes(row.link)} title='Chart' icon={InitialIcon(row.icon)}>
                                {
                                    row.subMenu.map((subMenu, sidk) => (
                                        <MenuItem key={sidk} active={(location.pathname).includes(subMenu.link)} component={<Link to={subMenu.link}/>}>{subMenu.label}</MenuItem>
                                    ))
                                }
                                
                            </SubMenu>
                        )
                    ))
                }
                <MenuItem onClick={logout} icon={InitialIcon('logout')}>Logout</MenuItem>
            </SidebarMenu>
        </Section>
    )
}

const Section = styled(Sidebar)`
    position: fixed !important;
    left: 0;
    top: 0;
    height: 100vh;
    color: var(--gray-3);
    z-index: 999;
    .sidebar__image{
        min-height: var(--topbar-size);
        display: flex;
        position: absolute;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

const SidebarMenu = styled(Menu)`
    margin-top: 15px;
    margin-top: calc(var(--topbar-size) + 15px);
    font-size: .957rem;
    .ps-submenu-root{
        &.ps-open{
            background-color: var(--menu-active-open) !important;
        }
        .ps-menuitem-root{
            font-size: .907rem;
            .ps-menu-button{
                padding-left: 54px;
                font-size: .89rem;
                opacity: 1;
                /* margin-right: 25px; */
                &::before{
                    content: "–";
                    font-weight: 700;
                    position: absolute;
                    margin-left: -1.3rem;
                }
            }
        }
    }
    .ps-submenu-content{
        inset: 25px auto auto 0px !important;
        background-color: var(--menu-active-open) !important;
        &.ps-open{
            background-color: var(--menu-active-open) !important;
        }
    }
    .ps-menu-button{
        &.ps-active{
            background-color: var(--menu-active-active) !important;
            color: var(--gray-1);
        }
        &:hover{
            background-color: var(--menu-active-open) !important;
        }
        .ps-menu-button{
            margin-right: 24px;
        }
        /* .ps-menu-icon{
            margin-right: 0;
        } */
    }
`