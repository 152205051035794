import Select from 'react-select';

export const SelectControl = (props) => {
    const {
        className,
        ...rest
    } = props
    return (
        <Select 
            className={`${className?className:''}`} 
            styles={{
                control: (baseStyle, state) => ({
                    ...baseStyle,
                    // backgroundColor: "var(--header-border)",
                    borderColor: state.isFocused ? 'var(--arlo-focus-border-color)' : 'var(--arlo-border-color)',
                    borderWidth: state.isFocused ? '1px': '1px',
                    boxShadow: "none"
                }),
                menu: (baseStyle, state) => ({
                    ...baseStyle,
                    marginTop: "4px",
                    borderRadius: "var(--border-radius)"
                })
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: "var(--border-radius)",
                colors: {
                  ...theme.colors,
                //   primary25: '#0b5ed7',
                  primary: '#0d6efd',
                },
            })}
            {...rest}
        />
    )
}