import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

export default function LinkButton(props) {
	const {
		children,
		className,
		...rest
	} = props
	return (
		<LinkButtonStyle className={`btn ${className}`} {...rest}>{children}</LinkButtonStyle>
	)
}

const LinkButtonStyle = styled(Link)`
	border-radius: var(--border-radius);
`