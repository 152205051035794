import Form from 'react-bootstrap/Form';
import { styled } from 'styled-components';

export const TextareaControl = (props) => {
    const {
        className,
        ...rest
    } = props
    return (
        <FTextAreaStyle as="textarea" className={`form-control ${className?className:''}`} {...rest}></FTextAreaStyle>
    )
}

const FTextAreaStyle = styled(Form.Control)`
    &:focus{
        box-shadow: none;
    }
    border-radius: var(--border-radius);
`