import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
} from "react-router-dom";

import Main from "../components/layouts/Main";
import Dashboard from '../components/pages/Dashboard'
import Profile from '../components/pages/profile/Index'
import UserProfile from '../components/pages/profile/Profile'
import UserPassword from '../components/pages/profile/Password'
import UserUpdate from '../components/pages/profile/Update'
import Feedback from '../components/pages/feedbacks/Index'
import FeedbackList from '../components/pages/feedbacks/List'
import FeedbackDetail from '../components/pages/feedbacks/Details'
import Client from '../components/pages/clients/Index'
import ClientList from '../components/pages/clients/List'
import Shop from '../components/pages/shops/Index'
import ShopList from '../components/pages/shops/List'
import ShopAdd from '../components/pages/shops/Add'
import ShopEdit from '../components/pages/shops/Edit'
import ShopHint from '../components/pages/shops/hints/Index'
import ShopPrint from '../components/pages/shops/print/Index'
import Users from '../components/pages/users/Index'
import UsersList from '../components/pages/users/List'
import RolesList from '../components/pages/roles/List'
import RolesAdd from '../components/pages/roles/Add'
import RolesEdit from '../components/pages/roles/Edit'
import Login from "../components/pages/login/Login";
import FOFError from "../components/pages/errors/FOFError";
import { ProtectedRoute } from "./ProtectRoute";

const router = createBrowserRouter([
    {
        path: "/login",
        Component: Login
    },
    {
        path: "",
        element: <ProtectedRoute><Main/></ProtectedRoute>,
        errorElement: <FOFError/>,
        children: [
            {
                path: '/',
                index: true,
                element: <Dashboard/>,
            },
            {
                path: '/profile',
                element: <Profile/>,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <UserProfile/>
                    },
                    {
                        path: 'update',
                        element: <UserUpdate/>
                    },
                    {
                        path: 'password',
                        element: <UserPassword/>
                    },
                ]
            },
            {
                path: '/feedback',
                // element:<HashRouter/>,
                Component: Feedback,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <FeedbackList/>
                    },
                    {
                        path: 'details/:id',
                        element: <FeedbackDetail/>
                    }
                ]
            },
            {
                path: '/client',
                // element:<HashRouter/>,
                Component: Client,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <ClientList/>
                    }
                ]
            },
            {
                path: '/servicearea',
                // element:<HashRouter/>,
                Component: Shop,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <ShopList/>
                    },
                    {
                        path: 'add',
                        element: <ShopAdd/>
                    },
                    {
                        path: 'edit/:id',
                        element: <ShopEdit/>
                    },
                    {
                        path: 'hints/:id',
                        element: <ShopHint/>
                    },
                    {
                        path: 'print/:id',
                        element: <ShopPrint/>
                    }
                ]
            },
            {
                path: '/usermanage',
                element:<Users/>,
                children: [
                    {
                        path: 'user',
                        index: true,
                        element: <UsersList/>
                    },
                    {
                        path: 'role',
                        element: <Outlet/>,
                        // children: routerh
                        children: [
                            {
                                path: '',
                                index: true,
                                element: <RolesList/>
                            },
                            {
                                path: 'add',
                                element: <RolesAdd/>
                            },
                            {
                                path: 'edit/:id',
                                element: <RolesEdit/>
                            },
                        ]
                    }
                ]
            }
        ]
    },
]);

export default function RouterConfig() {
    return(
        <RouterProvider router={router}/>
    )
}