import APIService from "../api.service";

export const Detail = () => {
    return APIService.get(`/profile`);
}
export const Create = (data) => {
    return APIService.post(`/profile`, data);
}
export const Patch = (data) => {
    return APIService.put(`/profile`, data);
}

const roleService = {
    Detail,
    Create,
    Patch,
}
export default roleService;