import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { styled } from 'styled-components'
import { InputControl } from '../../../fragments/form/Input'
import Button from '../../../fragments/button/Button'
import { MdLogin } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../providers/AuthProvider'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../../utilis/slices/user.slice'
import { Toaster } from 'react-hot-toast'
import { useState } from 'react'


export default function Login() {

    document.title = "Login"

    const { login } = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [btnLoading, setBtnLoading] = useState(false)
    const {datas, submitLoading} = useSelector(
        (state) => state.user
    )

    useEffect(() => {
        setBtnLoading(submitLoading)
        console.log(datas);
        if(datas.success){
            login(datas.data.token, datas.data)
            navigate('/')
        }
    }, [datas, login, navigate, submitLoading])
    

    const handleLogin = (e) => {
        e.preventDefault()
        const formData = {
            email: e.target.user.value,
            password: e.target.password.value,
        }
        dispatch(userLogin(formData))
    }
    return (
        <div className='container-fluid vh-100'>
            <div className='d-flex flex-grow-1 justify-content-center align-items-center vh-100'>
                <LoginMainStyle>
                    <h3>Admin Login</h3>
                    <LoginStyle>
                        <form onSubmit={handleLogin}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Username</Form.Label>
                                <InputControl
                                    type="text"
                                    required
                                    placeholder="Username"
                                    name="user"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='d-flex justify-content-between'>
                                    <span>Password</span>
                                    <Link>Forgot password ?</Link>
                                </Form.Label>
                                <InputControl
                                    type="password"
                                    required
                                    placeholder="Password"
                                    name="password"
                                />
                            </Form.Group>
                            <Button className="mt-3 w-100 btn-primary" loading={btnLoading}><MdLogin/> Login</Button>
                        </form>
                    </LoginStyle>
                </LoginMainStyle>
            </div>
            <Toaster />
        </div>
    )
}

const LoginMainStyle = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    box-shadow: var(--page-title-box-shadow);
    padding: 20px;
    border-radius: var(--border-radius);
    background-color: var(--card-bg);
    width: 25rem;
    h3{
        text-align: center;
        font-size: 23px;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1rem;
        opacity: .7;
    }
`

const LoginStyle = styled.div`
    padding: 0.5rem 1rem;
`