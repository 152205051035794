import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { TitleUContext } from '../../../providers/TitleProvider'

export default function Index() {
    
    const {setTitle} = useContext(TitleUContext)
    const {pathname} = useLocation()

    // Change Page Title
    useEffect(() => {
        setTitle({
            title: "Services",
            pageTitle: "Services"
        })
    },[pathname, setTitle])

    return (
        <div className='container-fluid'>
            <Outlet/>
        </div>
    )
}
