import APIService from "../api.service";

export const List = (params) => {
    return APIService.get(`/feedbacks`, {
        params: {
            per_page: params.perPage,
            page: params.page
        }
    });
}
export const DetailList = (params) => {
    return APIService.get(`/feedback`, {
        params: {
            per_page: params.perPage,
            page: params.page,
            shop_id: params.shop,
            text: params.search.text,
            rate: params.search.rate
        }
    });
}

const feedbackService = {
    List,
    DetailList
}
export default feedbackService;