import Form from 'react-bootstrap/Form';
import { styled } from 'styled-components';

export const SwitchControl = (props) => {
    const {
        className,
        ...rest
    } = props
    return (
        <SwitchStyle className={`${className}`} {...rest}></SwitchStyle>
    )
}

const SwitchStyle = styled(Form.Check)`
    .form-check-input:focus{
        box-shadow: none;
    }
    border-radius: var(--border-radius);
`