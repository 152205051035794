import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import feedbackService from "../networks/services/feedback.service"
import { toast } from "react-hot-toast"

const initialState = {
    data: {},
    datas: [],
    formError: [],
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
}

export const feedbackList = createAsyncThunk(
    "feedback/list",
    async ({perPage, page}) => {
        try {
            const resp = await feedbackService.List({perPage, page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const feedbackDetails = createAsyncThunk(
    "feedback/details",
    async ({perPage, page, shop, search}) => {
        try {
            const resp = await feedbackService.DetailList({perPage, page, shop, search})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const feedbackAction = createSlice({
    name: "feedback",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        //# feedback List region 
        .addCase(feedbackList.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(feedbackList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload?.data.data.data]
                state.meta = action.payload?.data.data.meta
                console.log(state.datas);
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(feedbackList.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# feedback List endregion
        //# feedback List region 
        .addCase(feedbackDetails.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(feedbackDetails.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload?.data.data.data]
                state.meta = action.payload?.data.data.meta
                console.log(state.datas);
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(feedbackDetails.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# feedback List endregion
    }
})

const { reducer } = feedbackAction;

export default reducer;