import React from 'react'
import UICard, { CardBody, CardHeader, CardTitle } from '../../../fragments/card/UICard'
import { useSelector } from 'react-redux'

export default function Profile() {
    
	const {data, dataLoading} = useSelector((state) => state.profile)
    
    return (
        <UICard>
            <CardHeader>
                <CardTitle>Profile</CardTitle>
            </CardHeader>
            <CardBody>
                <div className='row'>
                    <div className='col-md-3 col-lg-2'><strong>Name : </strong></div>
                    <div className='col-md-9'>{dataLoading?'...':data?.name}</div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-3 col-lg-2'><strong>Email : </strong></div>
                    <div className='col-md-9'>{dataLoading?'...':data?.email}</div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-3 col-lg-2'><strong>Phone : </strong></div>
                    <div className='col-md-9'>{dataLoading?'...':data?.phone}</div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-3 col-lg-2'><strong>Role : </strong></div>
                    <div className='col-md-9'>{dataLoading?'...':data?.roles[0].name}</div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-3 col-lg-2'><strong>Created At : </strong></div>
                    <div className='col-md-9'>{dataLoading?'...':data.created_at}</div>
                </div>
            </CardBody>
        </UICard>
    )
}