export function APIUri(params) {
    let Url = 'http://localhost:8000'
    let paralUrl = ''
    if (process.env.NODE_ENV==='production') {
       Url = '' 
    }
    if(params && (params!==undefined) && (params!=='/') && (params!=='/')){
        paralUrl = params
    }
    return Url + paralUrl
}

export function ImageURI(params) {
    let Url = 'http://localhost:8000/'
    let paralUrl = ''
    if (process.env.NODE_ENV==='production') {
       Url = '' 
    }
    if(!params){
        return ;
    }
    if(params.includes('://')){
        return params;
    }
    if(params && (params!==undefined) && (params!=='/') && (params!=='/')){
        paralUrl = params
    }
    return Url +'storage/'+ paralUrl
}
