import React, { useContext } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styled from 'styled-components'
import { MdLogout, MdMenu } from 'react-icons/md'

import { SideBarContext } from '../../providers/SidebarProvider'
import SearchInput from '../../fragments/form/SearchInput'
import { IoIosPerson } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../../providers/AuthProvider';
import { Link } from 'react-router-dom';

export default function TopBar() {
	const { logout, user } = useAuth()
	const {open,setOpen} = useContext(SideBarContext)
	return (
		<TopBarI className='navbar__section' open={open}>
			<div className='navbar__layout'>
				<NavBar>
					<div className='d-flex'>
						<IButton variant='default' type='button' onClick={setOpen}><MdMenu size={21}/></IButton>
						<SearchInput mainClass="ms-3" placeholder="Search..."/>
					</div>
					<div className='d-flex justify-content-end h-100 align-content-center'>
						<ProfileTool>
							<Dropdown as={ButtonGroup}>
								<Dropdown.Toggle id="profile" variant='light'>
									<div className='profile-img'>
										<IoIosPerson size={23}/>
									</div>
									<div className='profile-card'>
										<span className='user'>{user?.name}</span>
										<span className='position'>{user?.email}</span>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="super-colors">
									<Dropdown.Item as={Link} to={'/profile'} eventKey="1" className='d-flex align-items-center'>
										<FaUserCircle/>
										<span className='ms-2'>Profile</span>
									</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item onClick={logout} eventKey="4" className='d-flex align-items-center'>
										<MdLogout/>
										<span className='ms-2'>Logout</span>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</ProfileTool>
					</div>
				</NavBar>
			</div>
		</TopBarI>
	)
}

const TopBarI = styled.div`
	left: ${props => props.open?'var(--sidebar-sm-size)':'var(--sidebar-size)'};    
	position: fixed;
    top: 0;
    right: 0;
    z-index: 1002;
    background-color: var(--gray-0);
    -webkit-transition: width,left,right,300ms;
    transition: width,left,right,300ms;
    border-bottom: 1px solid var(--header-border);
`

TopBarI.defaultProps={
	open: Boolean
}

const NavBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: var(--topbar-size);
    padding: 0 1.5rem 0 calc(1.5rem * .5);
`
const IButton = styled(Button)`
	border: none;
	/* padding-left: 0rem; */
	padding-right: .60rem;
	&:first-child:active{
		color: unset;
		background-color: unset;
		border-color: unset;
	}
`

const ProfileTool = styled.div`
	display: flex;
	align-items: center;
	background-color: var(--card-header-bg);
	padding-left: 1rem;
	padding-right: 1rem;
	.btn-group{
		width: 100%;
		height: 100%;
		.dropdown-menu{
			border-radius: var(--border-radius);
			border: 0;
			box-shadow: var(--arlo-profile-card-shadow);
			-webkit-animation-name: DropDownSlide;
			animation-name: DropDownSlide;
			-webkit-animation-duration: .3s;
			animation-duration: .3s;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
		}
		.btn:active{
			background: unset;
		}
	}
	.dropdown-toggle{
		/* border-radius: 999px; */
		background: none;
		border: 0;
		border-radius: var(--border-radius);
		color: var(--arlo-text-color);
		padding: 0;
		display: flex;
		align-items: center;
		&::after{
			content: none;
		}
		.btn:first-child:active, .btn:active{
			background-color: unset;
		}
	}
	.profile-img{
		display: flex;
		width: 3rem;
		height: 3rem;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--border-color);
		border-radius: 50%;
	}
	.profile-card{
		margin-left: .6rem;
		display: flex;
		flex-direction: column;
		justify-content: start;
		.user{
			text-align: left;
			line-height: 1;
		}
		.position{
			text-align: left;
			font-size: .86rem;
			color: var(--arlo-text-color);
			opacity: .7;
			line-height: 1.5;
		}
	}
`