import React from 'react'
import { Outlet, useLocation } from 'react-router'
import { TitleUContext } from '../../../providers/TitleProvider'
import { useContext } from 'react'
import { useEffect } from 'react'
import UICard, { CardBody, CardHeader, CardTitle, CardTool } from '../../../fragments/card/UICard'
import LinkButton from '../../../fragments/button/LinkButton'
import { CgArrowLeft } from 'react-icons/cg'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { userProfile } from '../../../utilis/slices/profile.slice'

export default function Index() {
    
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
    // Change Page Title
	useEffect(() => {
		setTitle({
            title: "Profile Manage",
            pageTitle: "Profile",
            // subPageTitle: "Details"
		})
	},[pathname, setTitle])

    const dispatch = useDispatch()
    
    const profile = useCallback(() => {
        dispatch(userProfile())
    }, [dispatch])

    useEffect(() => {
        profile()
    }, [profile])
        
    return (
        <>
        <div className='row'>
            <div className='col-md-12'>
                <UICard className="mb-2">
                    <CardHeader>
                        <CardTitle>Profile</CardTitle>
                        <CardTool>
                            <LinkButton to={'..'} className="btn-secondary me-2"><CgArrowLeft/> Back</LinkButton>
                        </CardTool>
                    </CardHeader>
                </UICard>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>

                <UICard>
                    <CardBody className="p-0 py-2">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link className={`nav-link d-flex align-items-center${pathname==='/profile'?' disabled':''}`} aria-current="page" to={''}><MdKeyboardDoubleArrowRight/> My Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link d-flex align-items-center${pathname==='/profile/update'?' disabled':''}`} to={'update'}><MdKeyboardDoubleArrowRight/> Profile Update</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link d-flex align-items-center${pathname==='/profile/password'?' disabled':''}`} to={'password'}><MdKeyboardDoubleArrowRight/> Change Password</Link>
                        </li>
                    </ul>
                    </CardBody>
                </UICard>
            </div>
            <div className='col-md-9'>
                <Outlet/>
            </div>
        </div>
        </>
    )
}
