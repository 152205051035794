import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { Table, Thead } from '../../../fragments/table/Table'
import { Pagination } from '../../../fragments/pagination/Pagination'
import { TitleUContext } from '../../../providers/TitleProvider'
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import { PiMicrosoftExcelLogoDuotone } from 'react-icons/pi'
import { FaRegStar, FaStar } from 'react-icons/fa'
import LinkButton from '../../../fragments/button/LinkButton'
import { CgArrowLeft } from 'react-icons/cg'
import FilterUI from '../../../fragments/uilitis/FilterUI'
import SearchInput from '../../../fragments/form/SearchInput'
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2'
import { SelectControl } from '../../../fragments/form/Select'
import { useDispatch, useSelector } from 'react-redux'
import { feedbackDetails } from '../../../utilis/slices/feedback.slice'
import Rating from 'react-rating'
import { ImageURI } from '../../../_config'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import FormReducer from '../../../utilis/actions/FormReducer'
import { useCallback } from 'react'

const formIntial = {
    text: "",
    rate: 0
}

export default function Details() {
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const params = useParams()
	const [searchParams, ] = useSearchParams()
	const page = searchParams.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
	const {datas, dataLoading, meta} = useSelector((state) => state.feedback)
	const [show, setShow] = useState()
	const [image, setImage] = useState()

	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "Feedback Manage",
            pageTitle: "Feedback",
            subPageTitle: "Details"
		})
	},[pathname, setTitle])
	
	const shops = useCallback(({page=currentPage, search={}}) => {
		dispatch(
			feedbackDetails({
				perPage: 20,
				page: page,
				shop: params.id,
				search: search
			})
		)
	},[currentPage, dispatch, params.id])
	// Fetch Data
	useEffect(() => {
		shops(currentPage)
	}, [currentPage, dispatch, params.id, shops])
	// Filter Data
	useEffect(() => {
		if(formData.text && (formData.text).length < 4) return;
			
		shops({search: { text: formData?.text, rate: formData?.rate}})
	}, [formData.text, formData.rate, shops])

	const handleSearInput = (e, name) => {
		console.log(e);
        dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?e:e.target.value,
        })
	}
	const handleShowImage = (d) => {
		setShow(true)
		setImage(d)
	}
	
	return (
		<Fragment>
			<Outlet/>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Service List ({searchParams.get('shop')})</CardTitle>
						<CardTool>
							<LinkButton to={'..'} className="btn-secondary me-2"><CgArrowLeft/> Back</LinkButton>
							<Button className="btn-success filter-btn"><PiMicrosoftExcelLogoDuotone size={23}/> Export</Button>
						</CardTool>
					</CardHeader>
					<CardBody className="p-0">
						<FilterUI>
							<div className='d-flex justify-content-between align-items-center'>
								<div className='row flex-grow-1'>
									<div className='col-md-3 ms-1'>
										<SearchInput placeholder="Search..." name="text" onChange={handleSearInput}/>
									</div>
									<div className='col-md-2 d-flex align-items-center'>
										<Rating
											emptySymbol={<FaRegStar size={25}/>}
											fullSymbol={<FaStar size={25}/>}
											initialRating={formData?.rate}
											fractions={2}
											onChange={(e) => handleSearInput(e, "rate")}
										/>
									</div>
								</div>
							</div>
						</FilterUI>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th>No.</th>
										<th width="100">Rating</th>
										<th width="35%">Reason</th>
										<th>Name</th>
										<th>Phone</th>
										<th>Image</th>
										<th>Created At</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={8}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idx) => (
											<tr key={idx}>
												<td>{`${idx+parseInt(currentPage)}.`}</td>
												<td>
													<Rating
														readonly
														emptySymbol={<FaRegStar/>}
														fullSymbol={<FaStar/>}
														initialRating={ row.rate }
														fractions={2}
													/>
												</td>
												<td>
													<OverlayTrigger trigger={['hover', 'focus']} overlay={
														<Popover id="popover-basic">
															<Popover.Header as="h3">Feedback</Popover.Header>
															<Popover.Body>
																<p className='fs-6'>{row.description}</p>
															</Popover.Body>
														</Popover>
													}>
														<p className='table-text'>{ row.description }</p>
													</OverlayTrigger>
												</td>
												<td>{ row.name }</td>
												<td>{ row.phone }</td>
												<td>{ row.photo?<span className='link-underline text-primary cursor-pointer' onClick={() => handleShowImage(row.photo)}>Image</span>:""}</td>
												<td>{ row.created_at }</td>
											</tr>
										))
									}
									{
										((datas.length <= 0) || dataLoading) &&
										(
											<tr>
												{
													datas.length <= 0 && <td colSpan={8}><p className='text-center text-danger'>Empty data</p></td>
												}
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		<ImageShow
			show={show}
			handleClose={() => setShow(false)}
			image={image}
		/>
		</Fragment>
	)
}

const ImageShow = function Details(props) {
	return (
		<Modal show={props.show} onHide={props.handleClose}>
			<Modal.Header closeButton>
			<Modal.Title as={"h6"}>Feedback Image</Modal.Title>
			</Modal.Header>
			<img src={ImageURI(props.image)} alt=''/>
		</Modal>
	)
}
