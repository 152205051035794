import React, { useCallback, useContext, useEffect } from 'react'
import { TitleUContext } from '../../../../providers/TitleProvider'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { FormCheck, Table } from 'react-bootstrap'
import UICard, { CardBody, CardHeader, CardTitle, CardTool } from '../../../../fragments/card/UICard'
import LinkButton from '../../../../fragments/button/LinkButton'
import { MdAdd, MdEdit } from 'react-icons/md'
import FilterUI from '../../../../fragments/uilitis/FilterUI'
import SearchInput from '../../../../fragments/form/SearchInput'
import Button from '../../../../fragments/button/Button'
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2'
import { Thead } from '../../../../fragments/table/Table'
import { CgArrowLeft } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { hintsDelt, hintsList } from '../../../../utilis/slices/feedbackReason.slice'
import { IoMdTrash } from 'react-icons/io'
import { DeleteAlert } from '../../../../fragments/alert/DeleteAlert'
import HintForm from './HintForm'
import { Pagination } from '../../../../fragments/pagination/Pagination'

export default function Hints() {
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const params = useParams()
	const [search] = useSearchParams()
	const page = search.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
	const {datas, dataLoading, meta, info, status} = useSelector((state) => state.feedbackhint)

	const [hintIds, setHintIds] = useState({})
	const [show, setShow] = useState(false)
    const ids = (params.id).split('-')
	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "Shops",
            pageTitle: "Shops",
			subPageTitle: "Hints",
		})
	},[pathname, setTitle])
    
    // handle form action 
    const handleForm = (id) => {
		setShow(true)
		setHintIds({
            id: id,
            client: ids[1],
            shop: ids[0],
        })
	}
    // modal close
    const handleClose = () => {
		setShow(false)
		setHintIds(null)
	}
    const shops = useCallback((page=currentPage) => {
        dispatch(
            hintsList({
                perPage: 20,
                page: page,
                shop: ids[0]
            })
        )
    },[])
	// Fetch Data
	useEffect(() => {
		shops(currentPage)
	}, [currentPage, info, shops])
	useEffect(() => {
		if(!show && status==="submited")
		    shops()
	}, [show, status, shops])
    
	const handleDeleted = (id) => {
		DeleteAlert(
			()=>{
				dispatch(hintsDelt(id))
			}
		)
	}

    return (
        <>
        <div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Shop Hint ({search.get('shop')})</CardTitle>
						<CardTool>
							<LinkButton to={'..'} className="btn-secondary me-2"><CgArrowLeft/> Back</LinkButton>
							<Button className="btn btn-primary" onClick={handleForm}><MdAdd/> Add</Button>
						</CardTool>
					</CardHeader>
					<CardBody className="p-0">
						<FilterUI>
							<form action="">
								<div className='d-flex justify-content-between align-content-center'>
									<div className='row flex-grow-1'>
										<div className='col-md-3 ms-1'>
											<SearchInput placeholder="Search..."/>
										</div>
									</div>
									<div className='d-flex justify-content-end'>
										<Button className="btn-primary filter-btn"><HiMiniAdjustmentsHorizontal/> Filter</Button>
									</div>
								</div>
							</form>
						</FilterUI>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th>No.</th>
										<th>Reason</th>
										<th>Status</th>
										<th width="220">Created At</th>
										<th width="180">Created By</th>
										<th width="120">Action</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={8}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idx) => (
											<tr key={idx}>
												<td>{`${idx+parseInt(currentPage)}.`}</td>
												<td>{ row.name }</td>
												<td>
                                                <FormCheck // prettier-ignore
                                                    type="switch"
                                                    id="custom-switch"
                                                    value={row.status}
                                                    label={row.status===0?"Open":"Close"}
                                                />
                                                </td>
												<td>{ row.created_at }</td>
												<td>{ row.created_by }</td>
												<td>
													<div className='btn-group'>
														<Button onClick={() => handleForm(row.id)} className="btn-primary btn-sm"><MdEdit/></Button>
														<Button className="btn-danger btn-sm" onClick={() => handleDeleted(row.id)}><IoMdTrash/></Button>
													</div>
												</td>
											</tr>
										))
									}
									{
										((datas.length <= 0) || dataLoading) &&
										(
											<tr>
												{
													datas.length <= 0 && <td colSpan={8}><p className='text-center text-danger'>Empty data</p></td>
												}
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		<HintForm show={show} hintIds={hintIds} handleClose={handleClose}/>
        </>
    )
}
