import React from 'react'
import FormReducer, { FormError } from '../../../../utilis/actions/FormReducer'
import { useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../fragments/button/Button'
import { Form, Modal } from 'react-bootstrap'
import { InputControl } from '../../../../fragments/form/Input'
import { Modals } from '../../../../fragments/modal/Modal'
import { useEffect } from 'react'
import { hintsAdd, hintsEdit } from '../../../../utilis/slices/feedbackReason.slice'

const formIntial = {
    name: "",
    icon: "",
    status: 1,
    client_id: 1,
    shop_id: 1
}

export default function HintForm(props) {

    const { 
        hintIds,
        handleClose,
        show
    } = props

    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const [formEror, dispatchFormErr] = useReducer(FormError, formIntial)
    const { submitLoading, formError, status, datas } = useSelector((state) => state.feedbackhint)
    const dispatch = useDispatch()

    /// formdata handle state
    const handleFormField = (e, name) => {
        dispatchForm({
            type: "INPUT",
            field: name?name:e.target.name,
            value: name?[e.value]:e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Current Form Error reset
        dispatchFormErr({
            type: "RESET"
        })
        // Form submtting
        if(hintIds?.id){
            dispatch(
                hintsEdit({id: hintIds.id, data: formData})
            )
        } else {
            dispatch(
                hintsAdd(formData)
            )
        }
    }
    // Close form modal
    const handleCloseModal = () => {
		handleClose()
        dispatchForm({
            type: "RESET"
        })
	}
    // Handle close success submitted
    useEffect(() => {
        if(status){
            handleCloseModal()
        }
        return;
    }, [status])

    // form error show from response
    useEffect(() => {
        if(formError)
            Object.keys(formError).map((key, idk) => {
                dispatchFormErr({
                    type: "ERROR",
                    field: key,
                    error: formError[key],
                })
                return false;
            })
        
    }, [formError])

    // For form edit
    useEffect(() => {
        if(!hintIds?.id) return;
        const hintData = datas.find((row) => row.id===hintIds?.id)
        dispatchForm({
            type: "REPLACE",
            value: {
                name: hintData?.name,
                icon: hintData?.icon,
                status: hintData?.status,
                client_id: hintIds?.client,
                shop_id: hintIds?.shop
            },
        })
    }, [datas, hintIds?.client, hintIds?.id, hintIds?.shop])

    return (
        <Modals show={show} onHide={handleCloseModal}> 
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{hintIds?.id?"Edit":"Add"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Name</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="name"
                            value={formData?.name}
                            placeholder="Hints"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-outline-success" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button type="submit" className="btn-primary" loading={submitLoading}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modals>
    )
}
