import React, { useContext } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { styled } from 'styled-components'
import { TitleUContext } from '../../providers/TitleProvider'
import { Link, useLocation } from 'react-router-dom'


export default function BreadcrumbHeader() {
    const { pageTitle, subPageTitle } = useContext(TitleUContext)
    const { pathname } = useLocation()

    return (
        <SBreadcrumb>
            <h4 className='brad__page__title'>
                {pageTitle} {subPageTitle?`(${subPageTitle})`:''}
            </h4>
            <BradcrmbI>
                <Breadcrumb.Item linkAs="span">
                    <Link to={'/'}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active='false' linkAs="span">
                    {
                        subPageTitle?<Link to={pathname.split('/')[1]}>{pageTitle}</Link>:pageTitle
                    }
                    
                </Breadcrumb.Item>
                {
                    subPageTitle&&
                    <Breadcrumb.Item active>
                        {subPageTitle}
                    </Breadcrumb.Item>
                }
                
            </BradcrmbI>
        </SBreadcrumb>
    )
}

const SBreadcrumb = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 1.5rem;
    align-items: center;
    background-color: var(--gray-0);
    -webkit-box-shadow: var(--page-title-box-shadow);
    box-shadow: var(--page-title-box-shadow);
    border-bottom: 1px solid var(--vz-page-title-border);
    /* margin: -23px -1.5rem 1.5rem -1.5rem; */
    .brad__page__title{
        font-size: 1.1rem;
        font-weight: 500;
        margin: 0;
    }

    .breadcrumb-item + .breadcrumb-item::before{
        content: "";
        background-image: url(/images/icon/right-chevron.png);
        background-repeat: no-repeat;
        background-size: 8px 9px;
        display: inline-block;
        width: 10px;
        background-position: center;
        height: 100%;    
        margin-right: 5px;
    }
`

const BradcrmbI = styled(Breadcrumb)`
    .breadcrumb{
        margin-bottom: 0;
    }
`