import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import shopService from "../networks/services/shop.service"
import { toast } from "react-hot-toast"
import { toastError, toastSuccess, toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: [],
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
    submitLoading: false
}

export const shopLogin = createAsyncThunk(
    "shop/login", 
    async (data) => {
        try{
            const resp = await shopService.Login(data)
            return resp;
        } catch(e) {
            return e.response;
        }
    }
)

export const shopLogout = createAsyncThunk(
    "shop/logout", 
    async () => {
        try{
            const resp = await shopService.Logout()
            return resp;
        } catch(e) {
            return e.response;
        }
    }
)

export const shopList = createAsyncThunk(
    "shop/list",
    async ({perPage, page}) => {
        try {
            const resp = await shopService.List({perPage, page})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const shopAdd = createAsyncThunk(
    "shop/add",
    async (data) => {
        try {
            const resp = await shopService.Create(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const shopOne = createAsyncThunk(
    "shop/one",
    async (id) => {
        try {
            const resp = await shopService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const shopEdit = createAsyncThunk(
    "shop/edit",
    async ({id, data}) => {
        try {
            const resp = await shopService.Patch(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const shopDelt = createAsyncThunk(
    "shop/delt",
    async (id) => {
        try {
            const resp = await shopService.Delt(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const shopAction = createSlice({
    name: "shop",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        .addCase(shopLogin.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(shopLogin.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = action.payload?.data;
                state.error = "";
                toast.success(action.payload?.data.message);
            } else {
                state.error = action.payload?.data?.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.submitLoading = false;
        })
        .addCase(shopLogout.pending, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = action.payload?.data;
                state.error = "";
                toast.success(action.payload?.data.message);
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.submitLoading = false;
        })
        //# shop List region 
        .addCase(shopList.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(shopList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload?.data.data.data]
                state.meta = action.payload?.data.data.meta
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(shopList.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# shop List endregion
        //# shop List region 
        .addCase(shopOne.pending, (state, action) => {
            state.data = {}
            state.dataLoading = true
        })
        .addCase(shopOne.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload?.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(shopOne.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# shop List endregion
        //# shop Crate region 
        .addCase(shopAdd.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(shopAdd.fulfilled, (state, action) => {
            state.status = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
                toastSuccess(action.payload?.data.message);
            } else if(action.payload?.status >= 400 && action.payload?.status <= 499){
                if(action.payload?.data?.data?.error){
                    toastError(action.payload?.data.message)
                } else {
                    toastValidate(action.payload?.data.errors)
                }
            } else if(action.payload?.status >= 500 && action.payload?.status <= 599){
                toastError(action.payload?.data.message)
            }
            state.formError = action.payload?.data.errors
            state.submitLoading = false
        })
        .addCase(shopAdd.rejected, (state, action) => {
            state.error = action.payload?.data
            console.log(action.payload?.data);
            state.submitLoading = false
        })
        //# shop Crate endregion
        //# shop Edit region 
        .addCase(shopEdit.pending, (state, action) => {
            state.status = ""
            state.submitLoading = true
        })
        .addCase(shopEdit.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
                toastSuccess(action.payload?.data.message);
            } else if(action.payload?.status >= 400 && action.payload?.status <= 499){
                if(action.payload?.data?.data?.error){
                    toastError(action.payload?.data.message)
                } else {
                    toastValidate(action.payload?.data.errors)
                }
            } else if(action.payload?.status >= 500 && action.payload?.status <= 599){
                toastError(action.payload?.data.message)
            }
            state.formError = action.payload?.data.errors
            state.submitLoading = false
        })
        .addCase(shopEdit.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# shop Edit endregion
        //# shop Delt region 
        .addCase(shopDelt.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(shopDelt.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(action.payload?.data.message);
            }
            state.submitLoading = false
        })
        .addCase(shopDelt.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# shop Delt endregion
    }
})

const { reducer } = shopAction;

export default reducer;