import APIService from "../api.service";

export const Login = (body, config={}) => {
    return APIService.post(`/login`, body, config);
}

export const Profile = () => {
    return APIService.get(`/profile`);
}

export const ChangePassword = () => {
    return APIService.post(`/profile-password`);
}

export const Logout = () => {
    return APIService.post(`/profile-password`);
}
//#region 
export const List = ({
    perPage,
    page
}) => {
    return APIService.get(`/users`,{
        params: {
            per_page: perPage,
            page: page?page:1
        }
    });
}
export const Detail = (params) => {
    return APIService.get(`/users/${params}`);
}
export const Create = (data) => {
    return APIService.post(`/users`, data);
}
export const Patch = (params, data) => {
    return APIService.put(`/users/${params}`, data);
}
export const Delt = (params) => {
    return APIService.delete(`/users/${params}`);
}
//#endregion
const userService = {
    Login,
    Profile,
    ChangePassword,
    Logout,
    List,
    Detail,
    Create,
    Patch,
    Delt
}
export default userService;