import React from 'react'
import { styled } from 'styled-components'

export const Table = (props) => {
    const {
        children,
        className
    } = props
    return (
        <TableStyle className={`table ${className}`}>
            {children}
        </TableStyle>
    )
}

const TableStyle = styled.table`
    border-color: var(--border-color);
`
export const Thead = (props) => {
    const {
        children,
        className
    } = props
    return(
        <TheadStyle className={`${className}`}>{children}</TheadStyle>
    )
}

const TheadStyle = styled.thead`
    &.table-light{
        background-color: var(--table-bg);
        border-color: var(--table-border-bg)
    }
    th{
        border-bottom: 1px solid  var(--border-color);
    }
    
`