import React, { Fragment, useContext, useEffect, useState } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { Table, Thead } from '../../../fragments/table/Table'
import { Pagination } from '../../../fragments/pagination/Pagination'
import { TitleUContext } from '../../../providers/TitleProvider'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import LinkButton from '../../../fragments/button/LinkButton'
import { MdAdd, MdEdit } from 'react-icons/md'
import { IoMdEye, IoMdTrash } from 'react-icons/io'
import { DeleteAlert } from '../../../fragments/alert/DeleteAlert'
import { useDispatch, useSelector } from 'react-redux'
import { roleDelt, roleList } from '../../../utilis/slices/role.slice'

export default function List() {
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const [searchParams, ] = useSearchParams()
	const page = searchParams.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
	const {datas, dataLoading, meta, info} = useSelector((state) => state.role)

	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "User Manage",
            pageTitle: "User Manage",
            subPageTitle: "Role",
		})
	},[pathname, setTitle])

	const handleDeleted = (id) => {
		DeleteAlert(
			()=>{
				dispatch(roleDelt(id))
			}
		)
	}

	// Fetch Roles Data
	useEffect(() => {
		const roles = (page) => {
			dispatch(
				roleList({
					perPage: 20,
					page: page
				})
			)
		}
		roles(currentPage)
	}, [currentPage, dispatch, info])
	
	return (
		<Fragment>
			<Outlet/>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Role List</CardTitle>
						<CardTool>
							<LinkButton to={'/usermanage/role/add'} className="btn btn-primary"><MdAdd/> Add</LinkButton>
						</CardTool>
					</CardHeader>
					<CardBody className="p-0">
						<p className='my-2'></p>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th width={60}>No.</th>
										<th>Role</th>
										<th width="120">Action</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={5}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idk) => (
											<tr key={idk}>
												<td>{`${idk+parseInt(currentPage)}.`}</td>
												<td>{row.name}</td>
												<td>
													<div className='btn-group'>
														<Button className="btn-info btn-sm"><IoMdEye/></Button>
														<LinkButton to={`/usermanage/role/edit/${row.id}`} className="btn-primary btn-sm"><MdEdit/></LinkButton>
														<Button className="btn-danger btn-sm" onClick={() => handleDeleted(row.id)}><IoMdTrash/></Button>
													</div>
												</td>
											</tr>
										))
									}
									{
										((datas.length <= 0) || dataLoading) &&
										(
											<tr>
												{
													datas.length <= 0 && <td colSpan={5}><p className='text-center text-danger'>Empty data</p></td>
												}
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		</Fragment>
	)
}
