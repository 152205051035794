import APIService from "../api.service";

export const List = (params) => {
    return APIService.get(`/feedback_reasons`, {
        params: {
            per_page: params.perPage,
            page: params.page,
            shopId: params.shop,
        }
    });
}

export const Detail = (id, config={}) => {
    return APIService.get(`/feedback_reasons/${id}`, config);
}

export const Create = (body, config={}) => {
    return APIService.post(`/feedback_reasons`, body, config);
}

export const Patch = (id, body, config={}) => {
    return APIService.put(`/feedback_reasons/${id}`, body, config);
}

export const Delt = (id, config={}) => {
    return APIService.delete(`/feedback_reasons/${id}`, config);
}

const hintService = {
    List,
    Detail,
    Create,
    Patch,
    Delt
}
export default hintService;