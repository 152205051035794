import React from 'react'
import UICard, { CardBody, CardHeader, CardTitle } from '../../../fragments/card/UICard'
import { Form } from 'react-bootstrap'
import { InputControl } from '../../../fragments/form/Input'
import Button from '../../../fragments/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import FormReducer, { FormError } from '../../../utilis/actions/FormReducer'
import { useReducer } from 'react'
import { userProfileEdit } from '../../../utilis/slices/profile.slice'
import { useEffect } from 'react'

const formIntial = {
    name: "",
    phone: "",
    email: ""
}

export default function Update() {
    
	const { data } = useSelector((state) => state.profile)
    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const [formEror, dispatchFormErr] = useReducer(FormError, formIntial)
    const { submitLoading, formError } = useSelector((state) => state.profile)
    const dispatch = useDispatch()

    /// form input handle
    const handleFormField = (e) => {
        dispatchForm({
            type: "INPUT",
            field: e.target.name,
            value: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            userProfileEdit(formData)
        )
        dispatchFormErr({type: "RESET"})
    } 
    // form error show from response
    useEffect(() => {
        if(formError)
            Object.keys(formError).map((key, idk) => {
                dispatchFormErr({
                    type: "ERROR",
                    field: key,
                    error: formError[key],
                })
                return false;
            })
        
    }, [formError])
    
    useEffect(() => {
        dispatchForm({
            type: "REPLACE",
            value: {
                name: data?.name, 
                phone: data?.phone,
                email: data?.email
            },
        })
    }, [data])

    return (
        <UICard>
            <CardHeader>
                <CardTitle>Update</CardTitle>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleSubmit}>
                <Form.Group className="position-relative mb-3">
                        <Form.Label>Name</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="name"
                            value={formData?.name}
                            placeholder="User name"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.name && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.name}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Phone</Form.Label>
                        <InputControl 
                            type="text"
                            name="phone"
                            value={formData?.phone}
                            placeholder="User phone"
                            onChange={handleFormField}
                        />
                        {
                            formEror?.phone && <Form.Control.Feedback className='d-block' type='valid'>{formEror?.phone}</Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>Email</Form.Label>
                        <InputControl 
                            type="text"
                            required
                            name="email"
                            readOnly
                            value={formData?.email}
                            placeholder="User email"
                            disabled
                        />
                    </Form.Group>
                    <Button type="submit" className="btn-primary" loading={submitLoading}>
                        Save Change
                    </Button>
                </Form>
            </CardBody>
        </UICard>
    )
}
