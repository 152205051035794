import React from "react";

import { FaTachometerAlt } from "react-icons/fa";
import { HiUserGroup} from "react-icons/hi";
import { IoIosLogOut } from "react-icons/io";
import { MdOutlineAreaChart } from "react-icons/md";
import { BsShop } from "react-icons/bs";
import { VscOrganization } from "react-icons/vsc";

const Components = {
    dashboard: FaTachometerAlt,
    feedback: MdOutlineAreaChart,
    user_manage: HiUserGroup,
    client: VscOrganization,
    servicearea: BsShop,
    logout: IoIosLogOut
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (block) => {
    // Creates a React element.
    if (typeof Components[block.component] !== "undefined") {
        return React.createElement(Components[block.component], {
            key: block,
            block: block,
        });
    } else if (typeof Components[block] !== "undefined") {
        return React.createElement(Components[block], {
            key: block,
            block: block,
        });
    }
    // component doesn't exist yet
    return React.createElement(
        () => (
            <div>
                The component {block.toString()} has not been created yet.
            </div>
        ),
        { key: block._id }
    );
};
