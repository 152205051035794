import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import profileService from "../networks/services/profile.service"
import { toast } from "react-hot-toast"
import { toastSuccess, toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: [],
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
    submitLoading: false
}

export const userProfile = createAsyncThunk(
    "profile/one",
    async (id) => {
        try {
            const resp = await profileService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const userProfileEdit = createAsyncThunk(
    "profile/edit",
    async ({id, data}) => {
        try {
            const resp = await profileService.Create(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const userProfilePass = createAsyncThunk(
    "profile/change",
    async (data) => {
        try {
            const resp = await profileService.Patch(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const userAction = createSlice({
    name: "profile",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        //# User profile region 
        .addCase(userProfile.pending, (state, action) => {
            state.data = {}
            state.dataLoading = true
        })
        .addCase(userProfile.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload?.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error);
            }
            state.dataLoading = false
        })
        .addCase(userProfile.rejected, (state, action) => {
            state.error = action.payload?.data
            state.dataLoading = false
        })
        //# User Edit region 
        .addCase(userProfileEdit.pending, (state, action) => {
            state.status = ""
            state.submitLoading = true
        })
        .addCase(userProfileEdit.fulfilled, (state, action) => {
            state.status = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
                toastSuccess(action.payload?.data.message)
            } else {
                toastValidate(action.payload?.data.errors)
            }
            state.formError = action.payload?.data.errors
            state.submitLoading = false
        })
        .addCase(userProfileEdit.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# User Edit endregion
        //# User Edit region 
        .addCase(userProfilePass.pending, (state, action) => {
            state.status = ""
            state.submitLoading = true
        })
        .addCase(userProfilePass.fulfilled, (state, action) => {
            state.status = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "achieve"
                toastSuccess(action.payload?.data.message)
            } else {
                toastValidate(action.payload?.data.errors)
                state.formError = action.payload?.data.errors
            }
            state.submitLoading = false
        })
        .addCase(userProfilePass.rejected, (state, action) => {
            state.error = action.payload?.data
            state.submitLoading = false
        })
        //# User Edit endregion
    }
})

const { reducer } = userAction;

export default reducer;