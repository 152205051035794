import { Modal } from "react-bootstrap"
import { styled } from "styled-components"

export const Modals = (props) => {
    const {
        children,
        ...rest
    } = props
    return (
        <ModalStyle {...rest}>{children}</ModalStyle>
    )
}

const ModalStyle = styled(Modal)`
    .modal-content{
        border-radius: var(--border-radius);
        border: 1px solid var(--border-color)
    }
    .modal-header{
        border-bottom: 0px;
        background-color: rgb(var(--arlo-light-rgb));
        .btn-close{
            box-shadow: none;
            opacity: 0.5;
            padding: calc(var(--arlo-modal-header-padding-y) * 0.5) calc(var(--arlo-modal-header-padding-x) * 0.5);;
            &:hover{
                opacity: 0.7;
            }
        }
    }
    .modal-title{
        font-size: 1.05rem;
        line-height: 1.2rem;
    }
`