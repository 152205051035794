import React, { Fragment, useContext, useEffect, useState } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { Table, Thead } from '../../../fragments/table/Table'
import { Pagination } from '../../../fragments/pagination/Pagination'
import { TitleUContext } from '../../../providers/TitleProvider'
import { useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../../fragments/button/Button'
import { MdAdd, MdEdit } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import Add from './Add'
import FilterUI from '../../../fragments/uilitis/FilterUI'
import SearchInput from '../../../fragments/form/SearchInput'
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { clientDelt, clientList } from '../../../utilis/slices/client.slice'
import { DeleteAlert } from '../../../fragments/alert/DeleteAlert'
import { ImageURI } from '../../../_config'

export default function List() {
	
    const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const [searchParams, ] = useSearchParams()
	const page = searchParams.get('page')
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(page?page:1);
	const {datas, dataLoading, meta, info} = useSelector((state) => state.client)
	const [userId, setUserId] = useState(null)

	const [show, setShow] = useState(false)
    // Change Page Title
    useEffect(() => {
        setTitle({
            title: "Client Manage",
            pageTitle: "Client",
			subPageTitle: "List",
        })
    },[pathname, setTitle])

	const handleEdit = (id) => {
		setShow(true)
		setUserId(id)
	}

	// Fetch Data
	useEffect(() => {
		const shops = (page) => {
			dispatch(
				clientList({
					perPage: 20,
					page: page
				})
			)
		}
		shops(currentPage)
	}, [currentPage, info])

	const handleDeleted = (id) => {
		DeleteAlert(
			()=>{
				dispatch(clientDelt(id))
			}
		)
	}

	return (
		<Fragment>
		<div className='row'>
			<div className='col-md-12'>
				<UICard>
					<CardHeader>
						<CardTitle>Client List</CardTitle>
						<CardTool>
							<Button className="btn-primary" onClick={() => setShow(true)}><MdAdd/> Add</Button>
						</CardTool>
					</CardHeader>
					<CardBody className="p-0">
						<FilterUI>
							<form action="">
								<div className='d-flex justify-content-between align-content-center'>
									<div className='row flex-grow-1'>
										<div className='col-md-3 ms-1'>
											<SearchInput placeholder="Search..."/>
										</div>
									</div>
									<div className='d-flex justify-content-end'>
										<Button className="btn-primary filter-btn"><HiMiniAdjustmentsHorizontal/> Filter</Button>
									</div>
								</div>
							</form>
						</FilterUI>
						<div>
							<Table className='table-responsive'>
								<Thead className='table-light'>
									<tr>
										<th>No.</th>
										<th>Image</th>
										<th>Group</th>
										<th>Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th width="120">Action</th>
									</tr>
								</Thead>
								<tbody>
									{
										dataLoading ? <tr><td colSpan={8}><p className='text-center'>Loading ...</p></td></tr> :
										datas.map((row, idx) => (
											<tr key={idx}>
												<td>{`${idx+parseInt(currentPage)}.`}</td>
												<td><img src={ImageURI(row.logo)} width={50} alt=''/></td>
												<td>{ row.name }</td>
												<td>{ row.name }</td>
												<td>{ row.email }</td>
												<td>{ row.phone }</td>
												<td>
													<div className='btn-group'>
														<Button className="btn-info btn-sm"><MdAdd/></Button>
														<Button className="btn-primary btn-sm" onClick={() => handleEdit(row.id)}><MdEdit/></Button>
														<Button className="btn-danger btn-sm" onClick={() => handleDeleted(row.id)}><IoMdTrash/></Button>
													</div>
												</td>
											</tr>
										))
									}
									
								</tbody>
							</Table>
						</div>
						<div className='d-flex justify-content-end me-3'>
							<Pagination
								currentPage={meta.current_page}
								totalCount={meta.total}
								siblingCount={5}
								pageSize={meta.per_page}
								onPageChange={page => setCurrentPage(page)}
							/>
						</div>
					</CardBody>
				</UICard>
			</div>
		</div>
		<Add show={show} userId={userId} handleClose={() => setShow(!show)}/>
		</Fragment>
	)
}
