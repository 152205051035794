import './App.css';
import RouterConfig from './routes/Route';

function App() {
  return (
    <RouterConfig/>
  );
}

export default App;
