import React, { Fragment, useContext, useEffect } from 'react'
import UICard, { CardBody, CardTitle, CardHeader, CardTool } from '../../../fragments/card/UICard'
import { TitleUContext } from '../../../providers/TitleProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { CgArrowLeft } from 'react-icons/cg'
import LinkButton from '../../../fragments/button/LinkButton'
import RoleForm from './Form'
import { useDispatch, useSelector } from 'react-redux'
import FormReducer, { FormError } from '../../../utilis/actions/FormReducer'
import { useReducer } from 'react'
import { shopAdd } from '../../../utilis/slices/shop.slice'

const formIntial = {
	name: '',
	client_id: '',
	email: '',
	phone: '',
	country: '',
	region: '',
	township: '',
	address: '',
	latitude: '',
	longitude: '',
}

export default function Add() {
    
	const {setTitle} = useContext(TitleUContext)
	const {pathname} = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
    const [formData, dispatchForm] = useReducer(FormReducer, formIntial)
    const { status } = useSelector((state) => state.shop)
    const [formEror , dispatchFormErr] = useReducer(FormError, formIntial)

	// Change Page Title
	useEffect(() => {
		setTitle({
            title: "Services",
            pageTitle: "Services",
			subPageTitle: "Add",
		})
	},[pathname, setTitle])

	const onHandleSubmit = (e) => {
		e.preventDefault();
		// Current Form Error reset
        dispatchFormErr({
            type: "RESET"
        })
        // Form submtting
        dispatch(shopAdd(formData))
	}

	useEffect(() => {
		if(status==="achieve"){
			dispatchForm({
				type: "RESET",
				formIntial
			})
			navigate('..')
		}
	}, [status])

    return (
        <Fragment>
		<div className='row'>
			<div className='col-md-12 col-sm-12 col-lg-7'>
				<UICard>
					<CardHeader>
						<CardTitle>Service Add</CardTitle>
						<CardTool>
							<LinkButton to={'..'} className="btn-secondary"><CgArrowLeft/> Back</LinkButton>
						</CardTool>
					</CardHeader>
					<CardBody>
						<p className='my-2'></p>
						<RoleForm 
							handleSubmit={onHandleSubmit} 
							formIntial={formIntial}
							dispatchForm={dispatchForm}
							formData={formData}
							formEror={formEror}
						/>
					</CardBody>
				</UICard>
			</div>
		</div>
		</Fragment>
    )
}