import React from 'react'
import { styled } from 'styled-components'
import LinkButton from '../../../fragments/button/LinkButton'
import {RiDashboard2Line} from 'react-icons/ri'

export default function FOFError() {
    return (
        <ErrorStyle className='container-fluid vh-100'>
            <div>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src='/images/errors/wired.gif' alt=''/>
                </div>
                <h3>Oops! We can't find this page.</h3>
                <div className='text-center mt-5'>
                    <LinkButton className="btn-secondary"><RiDashboard2Line/> Back to Dashboard</LinkButton>
                </div>
            </div>
        </ErrorStyle>
    )
}

const ErrorStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 300px;
    }
    h3{
        font-size: 2.3rem;
        opacity: .8;
        text-align: center;
    }
`