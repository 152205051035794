import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import hintService from "../networks/services/feedbackReason.service"
import { toast } from "react-hot-toast"
import { toastSuccess, toastValidate } from '../../fragments/toast/toast'

const initialState = {
    data: {},
    datas: [],
    formError: {},
    meta: {
        current_page: 1,
        per_page: 10,
        last_page: 10,
        total: 10,
    },
    error: null,
    info: null,
    warn: null,
    status: "",
    dataLoading: false,
    submitLoading: false
}

export const hintsList = createAsyncThunk(
    "hints/list",
    async ({perPage, page, shop}) => {
        try {
            const resp = await hintService.List({perPage, page, shop})
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

export const hintsAdd = createAsyncThunk(
    "hints/add",
    async (data) => {
        try {
            const resp = await hintService.Create(data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const hintsOne = createAsyncThunk(
    "hints/one",
    async (id) => {
        try {
            const resp = await hintService.Detail(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const hintsEdit = createAsyncThunk(
    "hints/edit",
    async ({id, data}) => {
        try {
            const resp = await hintService.Patch(id, data)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)
export const hintsDelt = createAsyncThunk(
    "hints/delt",
    async (id) => {
        try {
            const resp = await hintService.Delt(id)
            return resp
        } catch (e) {
            return e.response;
        }
    }
)

const hintsSlice = createSlice({
    name: "feedbackhint",
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        //# hints List region 
        .addCase(hintsList.pending, (state, action) => {
            state.dataLoading = true
            state.info = ""
        })
        .addCase(hintsList.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.datas = [...action.payload.data.data.data]
                state.meta = action.payload.data.data.meta
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.dataLoading = false
        })
        .addCase(hintsList.rejected, (state, action) => {
            state.error = action.payload.data
            state.dataLoading = false
        })
        //# hints List endregion
        //# hints one region 
        .addCase(hintsOne.pending, (state, action) => {
            state.dataLoading = true
        })
        .addCase(hintsOne.fulfilled, (state, action) => {
            if (action.payload?.status === 200) {
                state.data = action.payload.data.data
            } else {
                state.error = action.payload?.data.message;
                toast.error(state.error?state.error:"Error!");
            }
            state.dataLoading = false
        })
        .addCase(hintsOne.rejected, (state, action) => {
            state.error = action.payload.data
            state.dataLoading = false
        })
        //# hints one endregion
        //# hints Crate region 
        .addCase(hintsAdd.pending, (state, action) => {
            state.formError = ""
            state.status = ""
            state.submitLoading = true
            state.info = ""
        })
        .addCase(hintsAdd.fulfilled, (state, action) => {
            state.error = ""
            state.info = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "submited"
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.formError = action.payload.data.errors
            state.submitLoading = false
        })
        .addCase(hintsAdd.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# hints Crate endregion
        //# hints Edit region 
        .addCase(hintsEdit.pending, (state, action) => {
            state.submitLoading = true
            state.status = ""
            state.info = ""
        })
        .addCase(hintsEdit.fulfilled, (state, action) => {
            state.formError = ""
            state.error = ""
            state.info = ""
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                state.status = "submited"
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.formError = action.payload.data.errors
            state.submitLoading = false
        })
        .addCase(hintsEdit.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# hints Edit endregion
        //# hints Delt region 
        .addCase(hintsDelt.pending, (state, action) => {
            state.submitLoading = true
        })
        .addCase(hintsDelt.fulfilled, (state, action) => {
            state.info = ''
            if (action.payload?.status === 200) {
                state.info = action.payload?.data.message
                toastSuccess(state.info)
            } else {
                toastValidate(action.payload.data.errors)
            }
            state.submitLoading = false
        })
        .addCase(hintsDelt.rejected, (state, action) => {
            state.error = action.payload.data
            state.submitLoading = false
        })
        //# hints Delt endregion
    }
})

const { reducer } = hintsSlice;

export default reducer;