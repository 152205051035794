import React from 'react'
import { QRCode } from 'react-qrcode-logo'
import { styled } from 'styled-components'
import { ImageURI } from '../../../../_config';
import './print.css'

export default function PrintFrame(props) {
    const {
        data
    } = props
    console.log(data);
    return (
        <PrintStyle id="printMe">
            <div className='print-me'>
                <div className='logo-area'>
                    <img src={ImageURI(data.client_logo)} alt=''/>
                    <h5>{data.client}</h5>
                </div>
                <div className='scan-area'>
                    <h3>Please share your feedback</h3>
                    <h6>Scan Here</h6>
                    <div className='qr-area'>
                        <QRCode
                            value={`http://localhost/${data.qr_code}`}
                            logoImage={ImageURI(data.client_logo)}
                            size={200}
                            qrStyle="dots"
                            eyeRadius={[
                                { // top/left eye
                                    outer: [10, 10, 0, 10],
                                    inner: [0, 10, 10, 10],
                                },
                                [10, 10, 10, 0], // top/right eye
                                [10, 0, 10, 10], // bottom/left
                            ]}
                            eyeColor={[
                                '#383838',  // top/left eye
                                '#0d6efd', // top/right eye
                                '#ffc0cb',  // bottom/left eye
                            ]}
                            removeQrCodeBehindLogo={true}
                            quietZone={20}
                        />
                    </div>
                    {/* <span>Or visit</span>
                    <div className=''>

                    </div> */}
                </div>
            </div>
        </PrintStyle>
    )
}


const PrintStyle = styled.div`
    margin: auto;
    position: relative;
    .print-me{
        width: 450px;
        height: auto;
        padding: .3rem;
        padding-bottom: 4rem;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 3px;
        background-color: #1a4567;
        position: relative;
        display: block;
        color: #ffffff;
    }
    .logo-area{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        background-color: white;
        padding: 20px 0;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 1rem;
        img{
            width: 80px;
            margin-right: 15px;
        }
        h5{
            font-size: 23px;
            color: #333;
        }
    }
    .scan-area{
        h3{
            text-transform: uppercase;
            font-weight: 400;
        }
        h6{
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 400;
            font-size: 20px;
        }
    }
`